

// Color list
//$color-white: #ffffff;
$color-navy-blue: #34495e;
$color-violet: #9b59b6;
$color-blue: #3498db;
$color-green: #00a65a;
$color-green-dark: #008d4c;
$color-yellow: #ffb606;
//var(--color-yellow-dark): #ffcd02;
$color-orange: #e67e22;
$color-red: #e74c3c;
$color-red-deep: #c0392b;

:root {

  --outline-color-main: rgb(0, 95, 204); // user agent color
  --outline-color-inverse: #fff;

  --no-text-decoration: inherit;

  --font-family-default: 'Source Sans Pro', sans-serif;

  --color-white: #fff;
  --color-yellow-dark: #ffcd02;
  --color-light-gray: #fafafa;
  --color-black: #000;

  --color-white-inverted: #fff;
  --color-white-reverse-on-dark: #fff;
  --color-white-bg: #fff;
  --color-white-t30: rgba(255, 255, 255, 0.7);
  --color-white-t15: rgba(255, 255, 255, 0.85);

  --color-black-border-t875: rgba(0, 0, 0, 0.125);

  --color-brown-dark: #6d6168;
  --color-brown-dark-bg: #6d6168;

  // Additional colors
  --color-text: #707980;
  --color-bright: #f7f9fa;
  --color-background: #edf0f5;
  --border-color: #e4e5e7;
  --bottom-menu-color: #0066b3;
  --error-color: #CC1212;

  // Energetika colors
  --basic-color-blue-dark: #0066b3;
  --basic-color-blue-light: #0BA1E2;
  --basic-color-yellow: #F2DD20;
  --basic-color-green: #b9cdb2;
  --basic-color-green-darker: #5cb85c;
  --basic-color-background-color: #6693df;
  --zvestoba-ogreva-color: #dc5f55;

  --klub-zvestoba-custom-button: #075f85;

  --basic-color-blue-dark-text: #0066b3;
  --basic-color-blue-dark-border: #0066b3;

  --basic-color-green-t35: rgba(185, 205, 178, 0.65);
  --basic-color-blue-dark-t80: rgba(0, 102, 179, 0.2);
  --basic-color-blue-dark-t45: rgba(0, 102, 179, 0.55);
  --basic-color-blue-dark-t40: rgba(0, 102, 179, 0.6);
  --basic-color-blue-dark-t35: rgba(0, 102, 179, 0.65);
  --basic-color-blue-dark-t25: rgba(0, 102, 179, 0.75);
  --basic-color-blue-dark-t20: rgba(0, 102, 179, 0.8);
  --basic-color-blue-dark-l30: #179bff; //lighten(#0066b3, 30%);
  --basic-color-blue-light-l30: #48c1f6; //lighten(#0BA1E2, 30%);
  --basic-color-yellow-t20: rgba(242, 221, 32, 0.8);
  --zvestoba-ogreva-color-t15: rgba(220, 95, 85, 0.85);

  //components
  --button-border-color-light: #e7e7e7;
  --button-border-color-gray: #ccc;
  --button-placeholder-color-text: rgba(85, 85, 85, 0.85);

  --color-border-gray-1: #d2d2d2;
  --color-border-gray-2: #d9d9d9;
  --color-border-gray-3: #f0f0f0;
  --color-border-gray-4: #ddd;
  --color-border-gray-5: #4c4c4c;

  --color-blue-bg-1: #0066b3;
  --color-blue-bg-2: #00508d;

  --color-light-red-bg: #f2685d;
  --color-light-red-bg-darkened-7: #f05245;

  --basic-color-blue-dark-button-bg: #0066b3;
  --basic-color-blue-dark-t20-button-bg: rgba(2, 89, 140, 0.8);
  --basic-color-blue-dark-t40-button-bg: rgba(0, 32, 89, 0.65);
  --basic-color-blue-dark-t20-bg: rgba(2, 89, 140, 0.8);
  --basic-color-blue-dark-t40-bg: rgba(0, 32, 89, 0.65);

  --border-none-monochromatic-enabled: none;
  --border-color-transparent-monochromatic-enabled: transparent;
  --background-color-transparent-monochromatic-enabled: transparent;
  --background-color-transparent-monochromatic-enabled-inverse: transparent;

  // --mat-dialog-color: rgba(0, 0, 0, 0.87);

  --navbar-background: #dadada;
  --navbar-border: #dadada;
  --navbar-color-shadow-black: rgba(0,0,0, .1);
  --navbar-color-shadow-white: rgba(255,255,255, .21);

  --navbar-dropdown-box-shadow: rgba(0, 0, 0, 0.16);

  --slider-arrows-layer-bg: rgba(0,0,0,0.5);

  --header-bg: #fff;

  --footer-bg: #f1f1f1;
  --footer-color-gray: #353535;

  --body-color-bg: #f1f1f1;
  --body-color-text: #212529;

  --text-color-gray-1: #212529;
  --text-color-gray-2: #555;
  --text-color-gray-3: #333;
  --text-color-gray-4: #838383;
  --text-color-gray-5: #555555;
  --text-color-gray-6: #666666;
  --text-color-gray-7: #424242;

  --form-control-focus: #bababa;

  --input-background-color: #fff;
  --input-text-color: #495057;
  --input-placeholder-color: #a9a9a9;

  --input-error-icon-url: url("/assets/images/ikone/exclamation-circle-red.svg");
  --input-error-color: #ac0b0b;

  --dropdown-menu-border: rgba(0, 0, 0, 0.15);

  --icons-color-gray-1: #ccc;
  --icons-fa-color-gray: #999;
  --icons-ul-li-fa-color: #dedede;

  --nipke-icons-url: url("/assets/images/energetika-v-druzbi/energetika-nipke-priporoca-icons-sprite.png");
  --tick-icon-gray: url("/assets/images/general/li-tick-silver.png");
  --tick-icon-green: url("/assets/images/general/li-tick-green.png");
  --tick-icon-input: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");

  --sidebar-nav-color-text: #8F8F8F;

  --table-color-gray-1-border: #dee2e6;
  --table-color-gray-1-text: #212529;
  --table-color-gray-1-bg: #f7f7f7;
  --table-color-caption-1: #6c757d;

  --news-color-text: #999999;
  --news-color-caption: #838383;

  --copyright-link-bg: #eee;

  --color-blue-light-bg: #0BA1E2;
  --color-gray-1-bg: #f5f5f5;

  --color-map-bg: #eeecea;

  --tt-blue-dark-bg:#2e5dad;
  --tt-blue-dark-text:#2e5dad;

  --background-stripes-nonchromatic: url("/assets/images/klub-zvestoba-ogreva/delimit-stripes.png");

  --povprasevanje-lighter-text-color: #666;
  --povprasevanje-main-color-t70: rgba(0, 92, 169, 0.3);
  --povprasevanje-main-color-t85: rgba(0, 92, 169, 0.15);
  --povprasevanje-main-color-bg-t70: rgba(0, 92, 169, 0.3);

  --razlaga-racuna-plin-color-bg: #ffd500;
  --razlaga-racuna-color-text: #575756;
  --razlaga-racuna-elektrika-color-bg: #a3c8aa;
  --razlaga-racuna-elektrika-color-text: #a3c8aa;
  --razlaga-racuna-toplota-color-bg: #71c5e2;
  --razlaga-racuna-toplota-color-bg2: #009fe3;
  --razlaga-racuna-box-shadow-color: grey;

  --tp-arrows-background: rgba(255, 255, 255, 0.19);

  --left-arrow-carousel: url("/assets/images/ikone/left-arrow.svg");
  --right-arrow-carousel: url("/assets/images/ikone/right-arrow.svg");
}

.text-paddings-wcag {
  line-height: 1.5 !important;
  letter-spacing: 0.12em !important;
  word-spacing: 0.16em !important;

  p {
    margin-bottom: 2em !important;
  }
}

.text-uppercase {
  --no-text-decoration: inherit !important;
  --no-text-transform: uppercase;
}

.font-arial {
  --font-family-default: Arial, sans-serif;
}

.font-verdana {
  --font-family-default: Verdana, sans-serif;
}

.font-opendislexic {
  --font-family-default: 'opendyslexic', sans-serif;
}

// Additional variables
$menu-width: 257px;
$menu-width-offset: 17px;
$main-transition: all 0.1s ease-out 0s;



$icons-color: #ccc;

// Other measures
$header-height: 70px;
$header-height-mobile: 50px;
$banner-height: 420px;
$footer-height: 120px;
$footer-height-mobile: 120px;

// Media queries
// $media-query-xxs: ~"only screen and (max-width: @{screen-xxs})";  // 380px
// $media-query-xs: ~"only screen and (max-width: @{screen-xs})";  // 480px
// $media-query-sm: ~"only screen and (max-width: @{screen-sm})";  // 768px
// $media-query-md: ~"only screen and (max-width: @{screen-md})";  // 992px
// $media-query-lg: ~"only screen and (max-width: @{screen-lg})";  // 1200px
// $media-query-xxs-min: ~"only screen and (min-width: @{screen-xxs-min})";  // 380px
// $media-query-xs-min: ~"only screen and (min-width: @{screen-xs-min})";  // 480px
// $media-query-sm-min: ~"only screen and (min-width: @{screen-sm-min})";  // 768px
// $media-query-md-min: ~"only screen and (min-width: @{screen-md-min})";  // 992px
// $media-query-lg-min: ~"only screen and (min-width: @{screen-lg-min})";  // 1200px
