@import "variables";
@import "mixins";

$color-white: var(--color-white);

.svetovalec {
  &.content-box-title {
    min-height: 130px;
    padding: 30px 20px;
    background-color: var(--basic-color-blue-dark);
    color: $color-white;
    display: block;
    border: 1px solid var(--border-color-transparent-monochromatic-enabled);

    &:active, &:hover, &:focus, &:visited {
      text-decoration: var(--no-text-decoration);
    }

    &:focus-visible {
      outline-color: var(--outline-color-main);
    }

    .div-img {
      height: 60px;
      width: 60px;
      padding: 2px;
      border: 2px solid $color-white;
      border-radius: 50%;
      display: inline-flex;
      background-size: contain;
      vertical-align: middle;
    }

    h1, h2 {
      color: $color-white;
      text-transform: uppercase;
      font-weight: normal;
      line-height: 60px;
      display: inline;
      margin: 0 0 0 15px;
      font-size: 21px !important;
    }

    @include media-query-lg {
      min-height: 150px;
      padding: 45px 20px;
    }

    @include media-query-md {
      height: auto;
      padding: 15px 10px;
      margin-bottom: 5px;

      img {
        height: 50px;
        width: 50px;
      }

      h2 {
        height: 50px;
        line-height: 50px;
      }
    }
  }

  &.content-box-description {
    background-color: var(--color-yellow-dark);
    color: var(--basic-color-blue-dark-text);
    padding: 15px 40px;
    text-align: left;
    border: 1px solid var(--border-color-transparent-monochromatic-enabled);

    &.height-control {
      min-height: 130px;
    }

    &.text-center {
      text-align: center;
    }

    p:last-child {
      margin-bottom: 0;
    }

    @include media-query-lg {
      &.height-control {
        min-height: 150px;
      }
    }

    @include media-query-md {
      text-align: center;

      &.height-control {
        height: auto;
      }
    }
  }
}
