@import "variables";
@import "mixins";

/* =Common Styles
-------------------------------------------------------------- */

$color-text: var(--color-white);
$color-background: var(--color-background);
$color-light-gray: var(--color-light-gray);
$color-border-gray: var(--color-border-gray-1);

::selection {
	background:var(--color-text); /* WebKit/Blink Browsers */
	color: $color-background;
	box-shadow:0;
}

::-moz-selection {
	background:var(--color-text); /* Gecko Browsers */
	color: $color-background;
	box-shadow:0;
}

pre[class*="htmlCodeCollapse"] {
    /*border-radius:$border-radius;*/
    margin:0 0 1.336em 0;
    padding:.3em 1em 1em 1em;
    font-size:1.125em;
    /*@include breakpoint(b2) {
        font-size:.806em;
    }*/
}

a { color:var(--basic-color-blue-light); cursor:pointer; }
a:hover { color:var(--basic-color-blue-light); text-decoration:underline; cursor:pointer; }
.link-white a { color: $color-text; }
.link-white a:hover { color: $color-light-gray; text-decoration:none; }

.center-vertically { display:table-cell; text-align:center; vertical-align:middle; }
.center-vertically-left { display:table-cell; text-align:left; vertical-align:middle; }
.center-vertically-right { display:table-cell; text-align:right; vertical-align:middle; }
.border-bottom-none { border-bottom:0 !important; }
.border-top-white { border-top:2px solid $color-text; }
.border-top { border-top:1px dotted $color-border-gray; }
.border-bottom { border-bottom:1px dotted $color-border-gray; }
.border { border:1px solid #E6E6E6; }
.border-white { border:1px solid $color-text !important;}
.no-border { border:0 !important;}
.no-padding { padding:0 !important; }
.no-margin { margin:0 !important; }
.no-border-radius { border-radius:0 !important; }
.br-50 { border-radius:50% !important; }
.br-2 { border-radius:2px !important; }
.full-width { width:100%; }

.background-light-grey { background-color: $color-light-gray !important; }
.background-white { background-color: $color-text !important; }

section {
	overflow:hidden;
}

/* =Typography
-------------------------------------------------------------- */
h1,h2,h3,h4,h5,h6 {
	/*font-family:'Raleway', sans-serif;*/
	font-weight:400;
	margin:0 0 14px;
}

h1 {
	font-weight:700;
	text-transform:uppercase;
}

h2 {
	font-weight:700;
	text-transform:uppercase;
}
h3 {
	font-size:24px;
	font-weight:400;
}

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small, .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small, h1 .small, h2 .small, h3 .small, h4 .small, h5 .small, h6 .small, .h1 .small, .h2 .small, .h3 .small, .h4 .small, .h5 .small, .h6 .small {
	font-weight:300;
	font-family:'Open Sans';
	padding-left:10px;
}


.pagination>li:first-child>a, .pagination>li:first-child>span {
    border-top-left-radius:0;
    border-bottom-left-radius:0;
}

.pagination>li:last-child>a, .pagination>li:last-child>span {
    border-top-right-radius:0;
    border-bottom-right-radius:0;
}
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    color: var(--color-white-inverted);
    background-color:var(--basic-color-blue-dark-button-bg);
    border-color:var(--basic-color-blue-dark-border);
}

.pagination>li>a, .pagination>li>span {
    padding:8px 14px;
    color:var(--basic-color-blue-dark-text);
    transition: all 0.4s ease-in-out;
    border: 1px solid var(--color-border-gray-4);
}

.pagination>li>a:focus, .pagination>li>a:hover, .pagination>li>span:focus, .pagination>li>span:hover {
    color: var(--color-white-inverted);
    background-color:var(--basic-color-blue-dark-button-bg);
    border-color:var(--basic-color-blue-dark-border);
}

.pagination>li>a, .pagination>li>span { border-radius:50% !important;margin:0 5px;}

/* =Sidebar Navigation
-------------------------------------------------------------- */
ul.sidebar-nav>li>span.badge {
	margin-top:12px;
	margin-right:6px;
}

.list-group-item:first-child,
.list-group-item:last-child,
ul.sidebar-nav {
	-webkit-border-radius:2px;
	   -moz-border-radius:2px;
			border-radius:2px;
}

.sidebar-nav .list-group-item:first-child {
	padding:25px;
}

.sidebar-nav-normal-1stchild .list-group-item:first-child {
	padding:0 !important;
}

ul.sidebar-nav {
    padding-left: 0;
}

ul.sidebar-nav li {
	list-style:none;
}

ul.sidebar-nav ul {
	margin:0;
	padding:0;
	background-color: $color-light-gray;
}

ul.sidebar-nav ul li {
	padding:0 15px;
	border-bottom: $color-border-gray 1px solid;
}

ul.sidebar-nav ul li:last-child {
	border-bottom:0;
}

ul.sidebar-nav a {
	display:block;
	text-decoration:none;
	color:var(--sidebar-nav-color-text);
}

ul.sidebar-nav a i.fa {
	width:15px;
}

ul.sidebar-nav ul li a {
	padding:3px;
	font-size:12px;
	color:#979797;
}

ul.sidebar-nav>li {
	padding:0;
}

ul.sidebar-nav>li>a {
	padding:7px 12px;
}

ul.sidebar-nav>li.list-group-item.active {
	border:var(---color-border-gray-4) 1px solid;
	background-color:transparent;
}

ul.sidebar-nav>li.active>a {
	background-color:var(--basic-color-blue-light);
}


ul.sidebar-nav ul li a:hover,
ul.sidebar-nav ul li.active>a {
	background-color:transparent !important;
	color:var(--basic-color-blue-light) !important;
}

ul.sidebar-nav > li.active>a {
	color: var(--color-white-inverted) !important;
}

.list-group-item {
	background :transparent;
	font-size:12px;
	border:1px solid var(--color-border-gray-4);
}

ul.sidebar-nav a:hover, ul.sidebar-nav ul li.active>a {
	background-color:var(--basic-color-blue-light);
	color: var(--color-white-inverted);
}

ul.sidebar-nav li.list-toggle:after {
	content:"\f10a";
	font-family:Ionicons;
	position:absolute;
	font-size:8px;
	right:15px;
	top:12px;
	color:var(--button-border-color-gray);
}

ul.sidebar-nav li.list-toggle.active:after {
	content:"\f104";
	color: $color-text;
}

ul.sidebar-nav li.list-toggle:hover:after {
	color: $color-text;
}

/* =Forms
-------------------------------------------------------------- */
.form-control:focus { border-color:var(--form-control-focus); outline:0; -webkit-box-shadow:none; box-shadow:none; }

// input[type=checkbox] {
// 	margin:6px 0 0;
// }

.input-group-addon {
	border-radius:0;
}

.form-control {
	border-radius:0;
	border:1px solid var(--button-border-color-gray);
	height:42px;
	display:inline-block;
	//-webkit-box-shadow:inset 0 2px 1px rgba(0,0,0,.07);
	//box-shadow:inset 0 2px 1px rgba(0,0,0,.07);
}

.checkout-form-border { border-left:3px solid var(--button-border-color-gray) }

/* =Carousel
-------------------------------------------------------------- */
.carousel-content {
	height:450px;
}
.carousel-dark {
	color:#ccc;
	height:3px;
    width:50px;
    color:#ccc;
    background:#ccc;
    font-size:0;
    border:0;
}
.carousel-caption {
	background-color:rgba(0, 0, 0, 0.5);
	width:100%;
	left:auto;
	right:auto;
	bottom:0;
	padding:20px;
}

/* =Content Boxes
-------------------------------------------------------------- */
.content-box {
	padding:20px 30px;
	text-align:center;
	margin-bottom:20px;
}
.content-box-bordered {
	border:1px solid #dadada;
}

.content-box-default {
	background:#ccc;
	-webkit-transition:all 0.4s ease-in-out;
	-moz-transition:all 0.4s ease-in-out;
	-o-transition:all 0.4s ease-in-out;
	-ms-transition:all 0.4s ease-in-out;
	transition:all 0.4s ease-in-out;
}
.content-box-hover:hover {
	color:#838383;
	-webkit-box-shadow:0px 0px 1px 1px rgba(0,0,0,0.07);
	-moz-box-shadow:0px 0px 1px 1px rgba(0,0,0,0.07);
	box-shadow:0px 0px 1px 1px rgba(0,0,0,0.07);
	-webkit-transition:all 0.4s ease-in-out;
	-moz-transition:all 0.4s ease-in-out;
	-o-transition:all 0.4s ease-in-out;
	-ms-transition:all 0.4s ease-in-out;
	transition:all 0.4s ease-in-out;
}
.content-box-default:hover {
	background:#aaa;
	border-bottom:5px solid #272727;
}
.content-box-primary {
	background:var(--basic-color-blue-light);
}
.content-box-success {
	background:#5cb85c;
}
.content-box-info {
	background:#5bc0de;
}
.content-box-warning {
	background:#f0ad4e;
}
.content-box-danger {
	background:#d9534f;
}
.content-box-shadow {
    -webkit-box-shadow:1px 1px 1px 0px rgba(0,0,0,0.02);
    -moz-box-shadow:1px 1px 1px 0px rgba(0,0,0,0.02);
    box-shadow:1px 1px 1px 0px rgba(0,0,0,0.02);
    padding:15px;
    background-color:#FAFAFA;
    border:1px solid #E6E6E6;
}

/* =Heading
-------------------------------------------------------------- */
.heading h1, .heading h2, .heading h3, .heading h4, .heading h5, .heading h6 {
	margin:0 0 -2px 0;
	padding-bottom:15px;
	display:inline-block;
	border-bottom:2px solid var(--basic-color-blue-light);
}
.heading {
	display:block;
	margin:0 0 20px 0;
	border-bottom:1px dotted $color-border-gray;
	font-size:12px;
}
.heading-caption {
	font-size:12px;
	font-family:'Open Sans', sans-serif;
	position:absolute;
	line-height:1.4;
	padding-left:25px;
	padding-top:4px;
}

/* =Header 1 and 2
-------------------------------------------------------------- */
.header { padding:12px 0 12px 0; border-bottom:1px dotted $color-border-gray; background: $color-light-gray; }
.header h1 {
	font-size:13px;
	margin:0;
	padding-top:4px;
	font-weight:700;
	text-transform:uppercase;
}

.header.header-dark {
	padding:21px 0 21px 0;
	border-bottom:1px dotted #636363;
	background:#262626;
}

.header-dark .breadcrumb a {
	color:#ECECEC;
}

.header-dark h1 {
	color: $color-text;
}

/* =Thumbnails
-------------------------------------------------------------- */
.thumbnail {
	padding:0;
	border-radius:0;
	border:0;
}

a.thumbnail:hover, a.thumbnail:focus, a.thumbnail.active {
	border-color:var(--basic-color-blue-light);
}

/* =Dividers
-------------------------------------------------------------- */
hr { border-top:1px dotted $color-border-gray; margin-top:30px; margin-bottom:30px; }
.dark-hr { border-top:1px dotted #4c4c4c; }

/* =Labels and Badges
-------------------------------------------------------------- */
.label {
	font-family:'Open Sans' sans-serif;
	text-transform: var(--no-text-transform);
	font-weight:300;
	border-radius:2px;
}
.badge-square {
	border-radius:0;
}
.badge-round {
	border-radius:2px;
}
.badge-primary {
	background-color:var(--basic-color-blue-light);
	color: $color-text;
}

.label-primary {
	background-color:var(--basic-color-blue-light);
}
