@use '@angular/material' as mat;

@include mat.core();

$enlj-theme: mat.m2-define-light-theme((
    color: (
        primary: mat.m2-define-palette(map-merge(mat.$m2-blue-palette, (900: var(--basic-color-blue-dark)))),
        accent: mat.m2-define-palette(mat.$m2-blue-palette, 900, A100, A400)
    ),
    typography: mat.m2-define-typography-config(),
    density: 0
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.core-theme($enlj-theme);
@include mat.slider-theme($enlj-theme);
@include mat.tooltip-theme($enlj-theme);
@include mat.slide-toggle-theme($enlj-theme);
@include mat.dialog-theme($enlj-theme);

.mat-mdc-dialog-surface.mdc-dialog__surface, .mat-mdc-dialog-title.mdc-dialog__title, .mat-mdc-dialog-content.mdc-dialog__content, .mat-mdc-dialog-actions.mdc-dialog__actions {
    background-color: var(--color-background) !important;
}

.mat-mdc-dialog-title.mdc-dialog__title {
    color: var(--color-text) !important;
}

mat-dialog-content {
    padding: 20px !important;
}

mat-dialog-content p,label {
    font-family: var(--font-family-default) !important;
    color: var(--color-text) !important;
}

.mat-mdc-dialog-surface.mdc-dialog__surface {
    border: 2px solid var(--color-text) !important;
}

.mat-mdc-dialog-title.mdc-dialog__title {
    padding: 20px 16px !important;
}

.mdc-tooltip__surface {
    color: var(--color-background) !important;
    background-color: var(--color-text) !important;
}

mat-dialog-actions {
    padding: 8px 16px !important;
}

.mdc-dialog__title::before {
    height: 0 !important;
}