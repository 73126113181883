@import "variables";
@import "mixins";

.content.klub-zvestoba-ogreva {
  p {
    font-size: 18px !important;

    a {
			font-size: 18px !important;
		}

    &.small {
      font-size: 80% !important;

      a {
        font-size: 80% !important;
      }
    }
  }

  p.notes {
    font-size: 12px !important;
    margin-top: 25px;
  }

  .small-font {
    font-size: 14px !important;
  }

  .border-top.divider {
    border-top: 3px dotted var(--color-border-gray-1);
  }

  .ugodnosti {
    padding: 40px 0;
    text-align: center;
  }

  .top-banner {
    height: 550px;
    position: relative;
    background-color: #000;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    .layer {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: transparentize(#000, 0.6);
      text-align: center;

      img {
        max-height: 180px;
        display: block;
        margin: 30px auto 50px auto;
      }

      .btn-blue {
        display: inline-block;
        margin: 20px;
        height: 60px;
        line-height: 56px;
        font-size: 22px !important;
        border: 1px solid #fff;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: var(--basic-color-blue-dark-l30);
          color: #fff;
        }

        &.border-only {
          color: var(--klub-zvestoba-custom-button);
          background-color: transparentize(#000, 0.9);
          border: 1px solid var(--klub-zvestoba-custom-button);
          transition: color 0.3s;
          transition: border 0.3s;

          &:hover {
            color: var(--basic-color-blue-light-l30);
            border: 1px solid var(--basic-color-blue-light-l30);
            background-color: transparentize(#000, 0.8);
          }
        }

        @include media-query-sm {
          width: 100%;
          max-width: 280px;
          margin: 20px 0;
        }
      }

      @include media-query-sm {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .layer-join {
      position: absolute;
      left: 30px;
      bottom: 20%;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      background: #dc5f55;
      font-size: 20px !important;
      padding: 40px 30px;
      font-weight: 700;
      color: #fff;
      text-align: center;
      border: 1px solid #fff;

      -webkit-transform: rotate(-7deg);
      -moz-transform: rotate(-7deg);
      -o-transform: rotate(-7deg);
      -ms-transform: rotate(-7deg);
      transform: rotate(-7deg);

      @media screen and (max-width: 1300px) {
        bottom: 35%;
        left: 15px;
        width: 170px;
        height: 170px;
        font-size: 18px !important;
        padding: 30px 20px;
      }

      @include media-query-sm {
        display: none;
      }
    }

    &.ugodnost {
      height: 420px;
      position: relative;

      .layer {
        padding: 10px 20px;

        h1 {
          color: #fff;
          font-size: 30px !important;
          margin: 40px auto;
          padding: 10px 0;
          border-top: 2px solid #fff;
          border-bottom: 2px solid #fff;
          max-width: 500px;
          width: 100%;
          text-transform: uppercase;
          font-style: normal;
          text-shadow: none;

          line-height: 1.4;
          font-weight: 400;

          @include media-query-sm {
            display: block;
          }
        }
      }
    }
  }

  .vstopite {
    padding: 10px 0;
    text-align: center;
    background-color: var(--zvestoba-ogreva-color);
    color: #fff;

    p {
      margin: 0;
      font-size: 24px !important;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .prihranki {
    padding: 40px 0;
    text-align: center;

    h3 {
      font-size: 22px !important;
      font-weight: 700;
      margin: 0;
    }

    img.prihranki-za-clane {
      display: inline-block;
    }

    @media screen and (max-width: 1350px) and (min-width: 1200px) {
      .col-md-lg-12 {
        margin-left: 1%;
        width: 98%;
      }
    }
  }

  .who-can {
    padding: 40px 0;
    text-align: center;
    font-size: 14px;

    h2 {
      font-size: 20px;
      margin-top: 20px;
      margin-bottom: 30px;

      font-weight: 400;
      text-transform: var(--no-text-transform);
    }

    img {
      max-width: 100px;
    }

    p {
      margin-top: 25px;
      margin-bottom: 20px;
    }
  }

  .delimiter {
    padding: 30px 0 100px 0;
    width: 100%;
    background-image: var(--background-stripes-nonchromatic);
    background-repeat: repeat-x;
    background-size: auto 80px;
    background-position: bottom left;

    .buttons-center {
      text-align: center;

      .btn-blue {
        display: inline-block;
      }
    }
  }

  .additional-explanation {
    .arrow {
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid var(--basic-color-blue-dark);
      margin-left: auto;
      margin-right: auto;
    }

    .text {
      padding: 10px;
      border: 2px solid var(--basic-color-blue-dark);
      border-radius: 5px;
    }
  }

  .pas-vsebina {
    padding: 40px 0;
    text-align: center;

    ul {
      list-style-position: inside;
    }

    .tile {
      padding: 30px 10px;
      text-align: center;
      max-width: 100%;

      img {
        max-width: 100%;
      }

      @include media-query-sm {
        max-width: none;
      }
    }
  }
}
