@import "variables";
@import "mixins";

$blue-light: var(--povprasevanje-main-color-t70);
$blue-light-bg: var(--povprasevanje-main-color-bg-t70);
$blue-lighter: var(--povprasevanje-main-color-t85);
$lighter-text-color: var(--povprasevanje-lighter-text-color);

.content.povprasevanja {
    .title-banner {
        background-color: $blue-light-bg;
        text-align: center;
        height: 250px;
        position: relative;
        border-top: 1px solid var(--border-color-transparent-monochromatic-enabled);
        border-bottom: 1px solid var(--border-color-transparent-monochromatic-enabled);

        > div {
            height: 250px;
        }

        h1, p {
            color: var(--color-white);
        }

        @include media-query-md {
            height: 200px;

            > div {
                height: 200px;
            }
        }

        @include media-query-sm {
            height: 170px;

            > div {
                height: 170px;
            }

            h1 {
                font-size: 28px;
                line-height: 31px;
            }
        }
    }

    .left-banner, .right-banner {
        min-height: calc(100vh - 330px);

        .col {
            flex-basis: 470px;
        }

        @include media-query-md {
            min-height: calc(100vh - 280px);
        }

        @include media-query-sm {
            min-height: auto;

            .col {
                flex-basis: 100%;
            }
        }
    }

    .right-banner {
        background-color: $blue-lighter;
        color: $lighter-text-color;

        border-left: 1px solid var(--border-color-transparent-monochromatic-enabled);
    }

    .btn {
        border-radius: 10px;

        &.btn-default {
            color:  var(--text-color-gray-5);
            border: 1px solid var(--button-border-color-gray);
        }

        &.w-100 {
            width: 100%;
        }

        &.btn-next {
            background-color: $blue-light;
            color: var(--color-white-inverted);
        }
    }

    .form-control {
        border-radius: 10px;
    }

    ul {
        list-style: none;
        padding-left: 0;

        li {
            i {
                margin-right: 10px;
                font-size: 12px;
            }
        }
    }

    .input-group {
        .input-group-append {
            margin-left: 15px;
        }
    }

    nav {
        font-size: 12px !important;
        color: $blue-light;

        i {
            margin: 0 3px;
        }
    }

    a {
        &.text {
            color: $lighter-text-color;
            text-decoration: var(--no-text-decoration);

            &:hover, &:active, &:focus {
                color: $lighter-text-color;
                text-decoration: var(--no-text-decoration);
            }
        }

        i {
            font-size: 12px;
        }

        &.add-more {
            color:  var(--text-color-gray-5);

            &:hover, &:active, &:focus {
                color:  var(--text-color-gray-5);
            }
        }
    }

    button.as-link {
        border: none;
        background-color: transparent;

        i {
            font-size: 12px;
        }

        &.btn-sm {
            i {
                font-size: 10px;
            }
        }
    }
}
