/* You can add global styles to this file, and also import other style files */
$fa-font-path: "/assets/fonts/fontawesome";

@import "variables";

@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "node_modules/@fortawesome/fontawesome-free/scss/brands";
@import "node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "node_modules/@fortawesome/fontawesome-free/scss/v4-shims";

/**
----- BOOTSTRAP IMPORTS START
**/
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
// @import "node_modules/bootstrap/scss/code";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/button-group";
@import "node_modules/bootstrap/scss/input-group";
// @import "node_modules/bootstrap/scss/custom-forms";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/card";
// @import "node_modules/bootstrap/scss/breadcrumb";
@import "node_modules/bootstrap/scss/pagination";
// @import "node_modules/bootstrap/scss/badge";
// @import "node_modules/bootstrap/scss/jumbotron";
@import "node_modules/bootstrap/scss/alert";
// @import "node_modules/bootstrap/scss/progress";
// @import "node_modules/bootstrap/scss/media";
// @import "node_modules/bootstrap/scss/list-group";
// @import "node_modules/bootstrap/scss/close";
// @import "node_modules/bootstrap/scss/toasts";
// @import "node_modules/bootstrap/scss/modal";
// @import "node_modules/bootstrap/scss/tooltip";
// @import "node_modules/bootstrap/scss/popover";
// @import "node_modules/bootstrap/scss/carousel";
// @import "node_modules/bootstrap/scss/spinners";
@import "node_modules/bootstrap/scss/utilities/align";
@import "node_modules/bootstrap/scss/utilities/background";
// @import "node_modules/bootstrap/scss/utilities/borders";
// @import "node_modules/bootstrap/scss/utilities/clearfix";
@import "node_modules/bootstrap/scss/utilities/display";
// @import "node_modules/bootstrap/scss/utilities/embed";
@import "node_modules/bootstrap/scss/utilities/flex";
@import "node_modules/bootstrap/scss/utilities/float";
@import "node_modules/bootstrap/scss/utilities/overflow";
@import "node_modules/bootstrap/scss/utilities/position";
// @import "node_modules/bootstrap/scss/utilities/screenreaders";
@import "node_modules/bootstrap/scss/utilities/shadows";
@import "node_modules/bootstrap/scss/utilities/sizing";
// @import "node_modules/bootstrap/scss/utilities/stretched-link";
@import "node_modules/bootstrap/scss/utilities/spacing";
@import "node_modules/bootstrap/scss/utilities/text";
@import "node_modules/bootstrap/scss/utilities/visibility";
// @import "node_modules/bootstrap/scss/print";

/**
----- BOOTSTRAP IMPORTS END
**/

/**
----- ANGULAR MATERIAL
**/
@import "material";

// @import "loader";
@import "raleway";
@import "layout";
@import "utilities";
@import "subpages";
@import "subpages-kzo";
@import "subpages2";
@import "news";
@import "povprasevanja";
@import "fonts";

$primary: var(--basic-color-blue-dark);
$danger: var(--error-color);
$color-white: var(--color-white);

// html {
// 	scroll-behavior: smooth;
// }

body, p, h1, h2, h3, h4, h5, h6, li, button, a {
    font-family: $font-family;
}

body {
    background-color: var(--body-color-bg);
    color: var(--body-color-text);
}

.heading {
    h1, h2, h3, h4, h5, h6 {
      font-family: $font-family;
	}
}

h4 {
	font-size: 18px;
}

button, a {

  &.outline-offset {
    outline-offset: 4px;
  }

  &.outline-offset-large {
    outline-offset: 10px;
  }

  &.outline-inverse {
    &:focus, &:active:focus, &.active:focus {
      outline-width: 5px;
      outline-color: var(--outline-color-inverse);
      outline-style: solid;
    }
  }

	&.btn {
		border-radius: 0.125rem;
    color: var(--text-color-gray-1);

    &:hover {
      color: var(--text-color-gray-1);
    }

    &:focus, &:active:focus, &.active:focus {
      outline-width: 5px;
      outline-color: var(--outline-color-main);
      outline-style: solid;
    }

    &.outline-inverse {
      &:focus, &:active:focus, &.active:focus {
        outline-color: var(--outline-color-inverse);
      }
    }
	}

  &.btn-primary {
    color: var(--color-white);
    background-color: var(--color-blue-bg-1);

    &:hover {
      color: var(--color-white-inverted);
      background-color: var(--color-blue-bg-2);
    }
  }

	&.btn-border-radius {
		border-radius: 10px;
	}

  &.btn-blue {
		display: block;
		margin: 10px 0;
		background-color: var(--basic-color-blue-dark);
		color: $color-white;
		height: 40px;
		line-height: 36px;
		padding: 2px 6px;
		font-size: 18px !important;
		font-weight: 700;
		border: 1px solid var(--border-color-transparent-monochromatic-enabled);
		width: 100%;
		max-width: 300px;
		text-align: center;
		transition: background-color 0.2s;
		text-transform: uppercase;
		box-sizing: border-box;

    //border-radius: 10px;

		//&:not([href]):not([tabindex])//:not(:hover)
    //{
		//	color: $color-white;
		//}

    &.outline-offset {
      outline-offset: 4px;
    }

		&.border-only {
			background-color: transparent;
			padding: 0 6px;
			border: 2px solid var(--basic-color-blue-dark-border);
			color: var(--basic-color-blue-dark-text);

			&:not([href]):not([tabindex]) {
				color: var(--basic-color-blue-dark-text);
			}
		}

    &.responsive-width {
			width: auto;
			width: initial;
			display: inline-block;
			padding: 0 20px;

			&.border-only {
				padding: 2px 20px;
			}
		}

		&.border-white {
			border: 2px solid $color-white;
		}

		&.text-white {
			color: $color-white !important;
		}

		&.btn-sm {
			height: 30px;
			line-height: 24px;
			font-size: 16px !important;
		}

		&:hover, &:focus, &:active {
			background-color: var(--basic-color-blue-light);
      text-decoration: var(--no-text-decoration);
      color: var(--color-white-inverted);

			&.border-only {
				background-color: transparent;
				border: 2px solid var(--basic-color-blue-light);
				color: var(--basic-color-blue-light);
			}

			&.border-white {
				border: 2px solid $color-white;
			}

			&.text-white {
				color: var(--color-white-inverted);
			}
		}

		i.fa {
			margin-right: 1rem;
		}

		@include media-query-sm {
			font-size: 16px !important;
			width: auto;
			max-width: 100%;
			height: 36px;
			line-height: 32px;
			padding: 2px 10px;

			&.border-only {
				padding: 0 10px;
			}

			&.responsive-width {
				padding: 2px 10px;

				&.border-only {
					padding: 0px 10px;
				}
			}

			&:hover, &:focus, &:active {
				&.border-only {
					line-height: 32px;
				}
			}
		}
	}
}

form {
	margin-top: 15px;
	margin-bottom: 30px;

  input {
    &.form-control, &.form-check-input {

      background-color: var(--input-background-color);
      color: var(--input-text-color);

      border-radius: 10px;

      &:focus, &:focus-visible {
        background-color: var(--input-background-color);
        color: var(--input-text-color);

        outline-width: 5px;
        outline-color: var(--outline-color-main);
        outline-style: solid;
        outline-offset: 0;
      }
    }

    &.form-check-input:checked {
      background-color: var(--basic-color-blue-dark);
    }

    &.form-check-input:checked[type="checkbox"] {
      background-image: var(--tick-icon-input);
    }
  }

  input[type="checkbox"] {
    // disable default appearence
    height: 1em;
    width: 1em;
    border: 1px solid var(--input-text-color);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 4px;
  }

  .form-control::placeholder {
    color: var(--input-placeholder-color);
  }

  .form-control:disabled {
    background-color: var(--input-background-color);
  }

  textarea {
    &.form-control {

      background-color: var(--input-background-color);
      color: var(--input-text-color);

      &:focus, &:focus-visible {
        background-color: var(--input-background-color);
        color: var(--input-text-color);

        outline-width: 5px;
        outline-color: var(--outline-color-main);
        outline-style: solid;
        outline-offset: 0;
      }
    }
  }

  button {
    &.form-control {
      background-color: var(--input-background-color);
      color: var(--input-text-color);

      &:focus, &:focus-visible {
        background-color: var(--input-background-color);
        color: var(--input-text-color);
      }
    }
  }

  //.form-control::-moz-placeholder{
  //  color: var(--input-placeholder-color);
  //  //opacity:1
  //}
  //
  //.form-control:-ms-input-placeholder{
  //  color: var(--input-placeholder-color);
  //}
  //
  //.form-control::-webkit-input-placeholder{
  //  color: var(--input-placeholder-color);
  //}

	label.control-label {
		font-weight: normal;
	}

	h2 {
		font-size: 28px;
		text-transform: var(--no-text-transform);
		margin-bottom: 30px;
		color: #555555;
		font-weight: 600;
	}

	h4 {
		margin-bottom: 0px;
		margin-top: 15px;
		font-weight: 600;
		color: #333333;
	}

	a.btn-blue {
		display: block;
		width: 250px;
		margin: 10px 0;
		background-color: var(--basic-color-blue-dark);
		color: $color-white;
		height: 40px;
		line-height: 40px;
		font-size: 18px;
		font-weight: 800;
		border: none;
		text-align: center;
		transition: background-color 0.2s;
		text-transform: uppercase;

		&:hover, &:focus, &:active {
			background-color: var(--basic-color-blue-light);
			text-decoration: var(--no-text-decoration);
		}
	}

	p.zakljucek {
		margin-top: 40px;
		margin-bottom: 40px;
	}

	.alert {
		h2, h3, h4 {
			color: var(--basic-color-blue-dark);
			margin-bottom: 10px;

      font-size: 1rem;
      font-weight: 600;
      margin-top: 15px;
		}

		p {
			color: var(--basic-color-blue-dark);
		}
	}

  .error-color {
    color: var(--error-color);
  }

	.error-border {
		border-color: var(--input-error-color);
		background-color: transparentize(#ac0b0b, 0.9);

    background-image: var(--input-error-icon-url);
    background-repeat: no-repeat;
    background-position: right 0.5625rem center;
    background-size: 1.125rem 1.125rem;
	}

	.help-block.error {
		color: var(--input-error-color);
		font-size: 14px !important;
		margin-bottom: 0;
    margin-top: 3px;
	}



	p.small-font {
		font-size: 14px !important;
	//	font-style: italic;
		margin-bottom: 0;
		margin-left: 5px;
		color: #777777;

		a {
	//		font-style: italic;
			font-size: 14px !important;
		}

		@include media-query-sm {
			font-size: 12px !important;
			//margin-bottom: 10px;

			a {
				font-size: 12px !important;
			}
		}
	}
}

.arrow-down {
	// box-shadow: inset 0 20px 30px -20px rgba(0, 0, 0, 0.8);
	box-shadow: inset 0 18px 20px -20px rgba(0, 0, 0, 0.8);
	height: 30px;
	position: relative;
	overflow: hidden;

	i {
		position: absolute;
		color: $color-white;
		font-size: 38px;
		line-height: 8px;
		top: 0;
		left: 30%;
		display: block;
	}

	&.grey {
		i {
			color: #fefefe;
		}
	}

	&.background-light-grey.dark {
		background-color: var(--navbar-background) !important;
	}
}

.dropdown {

	.btn {
		border: 1px solid var(--button-border-color-gray);
	}

	&.full-width {
		width: 100%;

		ul, button {
			width: 100%;
			text-align: left;

      &:focus, &:focus-visible {
        outline-width: 5px;
        outline-color: var(--outline-color-main);
        outline-style: solid;
        outline-offset: 0;
      }
		}

		ul.dropdown-menu {
			max-height: 400px;
			overflow-y: auto;
			padding: 5px 0;
		}

		ul li a {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
}

.dropdown-menu {
  background-color: var(--color-white-bg);
  border: 1px solid var(--dropdown-menu-border);

  .dropdown-item {
    background-color: var(--color-white-bg);
    color: var(--color-text);
  }
}

p, a, li, button, label, .md-label, td, th {
	font-size: 16px;
	line-height: 1.4;

	@include media-query-sm {
		font-size: 14px;
		//line-height: 1.3;
	}
}

button, select, input {
  text-transform: var(--no-text-transform);
}

div, button, a, p{
  &:focus-visible {
    outline-width: 5px;
    outline-color: var(--outline-color-main);
    outline-style: solid;
  }
}

.label {
	margin-right: 3px;
	text-transform: var(--no-text-transform);
	border-radius: 2px;
	display: inline;
	padding: .2em .6em .3em;
	font-size: 90%;
	font-weight: 300;
	line-height: 1;
	color: $color-white;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;

	&.label-primary {
	    background-color: var(--color-blue-light-bg);
	}
}

#bg-boxed {
	background-image: url("/assets/images/raleway/patterns/pattern2.png");
	background-repeat: repeat;
	height: 100%;
	overflow-x: hidden;
}

ul {
	list-style-image: var(--tick-icon-green);
	padding-left: 30px;

	li ul {
		list-style-image: none;
		list-style-type: disc;
	}
}

.boxed {
	background-color: $color-white;
	height:100%;
}

.footer-info-icons {
	padding: 0;
	width: 25px;
}

.dotted-divider {
	height: 1px;
	border-top: 1px dotted rgba(0,0,0,.1);
}

.list-no-results {
	p.icon {
		text-align: center;
		font-size: 40px !important;
		margin: 30px 0 0 0;
	}

	p.text {
		text-align: center;
		margin: 0 0 30px 0;
	}
}

table.table {

  color: var(--table-color-gray-1-text);

	tr th {
		background-color: var(--table-color-gray-1-bg);
	}

  th, td {
    border-color: var(--table-color-gray-1-border);
  }

  caption {
    color: var(--table-color-caption-1);
  }
}

.card {
  background-color: var(--color-white-bg);
}

.zvestoba-klub-img {
  background-image: url("/assets/images/ikone/wcag/zvestoba-ogreva-klub-transparent.png");
}

.racun-img {
  background-image: url("/assets/images/ikone/racun-transparent.png");
}

.delovna-celada-img {
  background-image: url("/assets/images/ikone/delovna-celada-transparent.png");
}

.oblacek-img {
  background-image: url("/assets/images/ikone/oblacek-transparent.png");
}

.zelena-prestolnica-img {
  background-image: url("/assets/images/general/evropska-prestolnica.png");
}
.clovek-racunalnik-img {
  background-image: url("/assets/images/ikone/clovek-racunalnik-transparent.png");
}


.theme-black-white {
  .zvestoba-klub-img {
    background-image: url("/assets/images/ikone/wcag/zvestoba-ogreva-klub-black.png");
  }
  .racun-img {
    background-image: url("/assets/images/ikone/wcag/racun-black.png");
  }
  .delovna-celada-img {
    background-image: url("/assets/images/ikone/wcag/delovna-celada-black.png");
  }
  .oblacek-img {
    background-image: url("/assets/images/ikone/wcag/oblacek-black.png");
  }
  .zelena-prestolnica-img {
    background-image: url("/assets/images/general/wcag/logo-zpe-footer-black.png");
  }
  .clovek-racunalnik-img {
    background-image: url("/assets/images/ikone/wcag/clovek-racunalnik-black.png");
  }

  .fab-container {
    a {
      &:hover, &:active, &:focus {

        .zvestoba-klub-img {
          background-image: url("/assets/images/ikone/wcag/zvestoba-ogreva-klub-transparent.png");
        }
        .racun-img {
          background-image: url("/assets/images/ikone/racun-transparent.png");
        }
        .delovna-celada-img {
          background-image: url("/assets/images/ikone/delovna-celada-transparent.png");
        }
        .oblacek-img {
          background-image: url("/assets/images/ikone/oblacek-transparent.png");
        }

      }
    }
  }
}


.theme-white-black {
  .zvestoba-klub-img {
    background-image: url("/assets/images/ikone/wcag/zvestoba-ogreva-klub-transparent.png");
  }
  .racun-img {
    background-image: url("/assets/images/ikone/racun-transparent.png");
  }
  .delovna-celada-img {
    background-image: url("/assets/images/ikone/delovna-celada-transparent.png");
  }
  .oblacek-img {
    background-image: url("/assets/images/ikone/oblacek-transparent.png");
  }
  .zelena-prestolnica-img {
    background-image: url("/assets/images/general/wcag/logo-zpe-footer-white.png");
  }
  .clovek-racunalnik-img {
    background-image: url("/assets/images/ikone/clovek-racunalnik-transparent.png");
  }
  .fab-container {
    a {
      &:hover, &:active, &:focus {
        .zvestoba-klub-img {
          background-image: url("/assets/images/ikone/wcag/zvestoba-ogreva-klub-black.png");
        }

        .racun-img {
          background-image: url("/assets/images/ikone/wcag/racun-black.png");
        }

        .delovna-celada-img {
          background-image: url("/assets/images/ikone/wcag/delovna-celada-black.png");
        }

        .oblacek-img {
          background-image: url("/assets/images/ikone/wcag/oblacek-black.png");
        }
      }
    }
  }
}

.theme-black-beige {
  .zvestoba-klub-img {
    background-image: url("/assets/images/ikone/wcag/zvestoba-ogreva-klub-black.png");
  }
  .racun-img {
    background-image: url("/assets/images/ikone/wcag/racun-black.png");
  }
  .delovna-celada-img {
    background-image: url("/assets/images/ikone/wcag/delovna-celada-black.png");
  }
  .oblacek-img {
    background-image: url("/assets/images/ikone/wcag/oblacek-black.png");
  }
  .zelena-prestolnica-img {
    background-image: url("/assets/images/general/wcag/logo-zpe-footer-black.png");
  }
  .clovek-racunalnik-img {
    background-image: url("/assets/images/ikone/wcag/clovek-racunalnik-black.png");
  }
  .fab-container {
    a {

      &:hover, &:active, &:focus {
        .zvestoba-klub-img {
          background-image: url("/assets/images/ikone/wcag/zvestoba-ogreva-klub-beige.png");
        }

        .racun-img {
          background-image: url("/assets/images/ikone/wcag/racun-beige.png");
        }

        .delovna-celada-img {
          background-image: url("/assets/images/ikone/wcag/delovna-celada-beige.png");
        }

        .oblacek-img {
          background-image: url("/assets/images/ikone/wcag/oblacek-beige.png");
        }
      }
    }
  }
}

.theme-yellow-blue {
  .zvestoba-klub-img {
    background-image: url("/assets/images/ikone/wcag/zvestoba-ogreva-klub-yellow.png");
  }
  .racun-img {
    background-image: url("/assets/images/ikone/wcag/racun-yellow.png");
  }
  .delovna-celada-img {
    background-image: url("/assets/images/ikone/wcag/delovna-celada-yellow.png");
  }
  .oblacek-img {
    background-image: url("/assets/images/ikone/wcag/oblacek-yellow.png");
  }
  .zelena-prestolnica-img {
    background-image: url("/assets/images/general/wcag/logo-zpe-footer-yellow.png");
  }
  .clovek-racunalnik-img {
    background-image: url("/assets/images/ikone/wcag/clovek-racunalnik-yellow.png");
  }
  .fab-container {
    a {
      &:hover, &:active, &:focus {
        .zvestoba-klub-img {
          background-image: url("/assets/images/ikone/wcag/zvestoba-ogreva-klub-blue.png");
        }

        .racun-img {
          background-image: url("/assets/images/ikone/wcag/racun-blue.png");
        }

        .delovna-celada-img {
          background-image: url("/assets/images/ikone/wcag/delovna-celada-blue.png");
        }

        .oblacek-img {
          background-image: url("/assets/images/ikone/wcag/oblacek-blue.png");
        }
      }
    }
  }
}

.theme-black-yellow {
  .zvestoba-klub-img {
    background-image: url("/assets/images/ikone/wcag/zvestoba-ogreva-klub-black.png");
  }
  .racun-img {
    background-image: url("/assets/images/ikone/wcag/racun-black.png");
  }
  .delovna-celada-img {
    background-image: url("/assets/images/ikone/wcag/delovna-celada-black.png");
  }
  .oblacek-img {
    background-image: url("/assets/images/ikone/wcag/oblacek-black.png");
  }
  .zelena-prestolnica-img {
    background-image: url("/assets/images/general/wcag/logo-zpe-footer-black.png");
  }
  .clovek-racunalnik-img {
    background-image: url("/assets/images/ikone/wcag/clovek-racunalnik-black.png");
  }
  .fab-container {
    a {
      &:hover, &:active, &:focus {
        .zvestoba-klub-img {
          background-image: url("/assets/images/ikone/wcag/zvestoba-ogreva-klub-yellow.png");
        }

        .racun-img {
          background-image: url("/assets/images/ikone/wcag/racun-yellow.png");
        }

        .delovna-celada-img {
          background-image: url("/assets/images/ikone/wcag/delovna-celada-yellow.png");
        }

        .oblacek-img {
          background-image: url("/assets/images/ikone/wcag/oblacek-yellow.png");
        }
      }
    }
  }
}

.theme-lime-black {
  .zvestoba-klub-img {
    background-image: url("/assets/images/ikone/wcag/zvestoba-ogreva-klub-green.png");
  }
  .racun-img {
    background-image: url("/assets/images/ikone/wcag/racun-lime.png");
  }
  .delovna-celada-img {
    background-image: url("/assets/images/ikone/wcag/delovna-celada-lime.png");
  }
  .oblacek-img {
    background-image: url("/assets/images/ikone/wcag/oblacek-lime.png");
  }
  .zelena-prestolnica-img {
    background-image: url("/assets/images/general/wcag/logo-zpe-footer-green.png");
  }
  .clovek-racunalnik-img {
    background-image: url("/assets/images/ikone/wcag/clovek-racunalnik-lime.png");
  }
  .fab-container {
    a {
      &:hover, &:active, &:focus {
        .zvestoba-klub-img {
          background-image: url("/assets/images/ikone/wcag/zvestoba-ogreva-klub-black.png");
        }

        .racun-img {
          background-image: url("/assets/images/ikone/wcag/racun-black.png");
        }

        .delovna-celada-img {
          background-image: url("/assets/images/ikone/wcag/delovna-celada-black.png");
        }

        .oblacek-img {
          background-image: url("/assets/images/ikone/wcag/oblacek-black.png");
        }
      }
    }
  }
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--basic-color-blue-dark-text) !important;
}

.mat-slide-toggle-thumb {
  background-color: var(--basic-color-blue-dark-text) !important;
}
