$color-text: var(--color-white);
$news-color-text: var(--news-color-text);

ul.nav.nav-pills.novice-tags {
	font-size: 14px;
	font-weight: 400;
	display: inline-block;
	margin-bottom: 10px;
	padding: 0;

	li.filter {
        float: left;
        display: inline-block;
        padding: 0;
        cursor: pointer;
        font-size: 14px;
        color: $news-color-text;
        border: 2px solid var(--button-border-color-light);
        border-radius: 2px;
        margin-right: 10px;
        margin-bottom: 10px;
        line-height: 30px;

        a {
            color: $news-color-text;
            font-weight: 400;
            border: none;
            background: none;
            text-transform: var(--no-text-transform);
            padding: 2px 20px;

            &:hover, &:active, &:visited {
                color: $news-color-text;
                background: none;
                border: none;
            }
        }

	&.active {
		color: $color-text;
		background: var(--basic-color-blue-light);
		border: 2px solid var(--basic-color-blue-light);

		a {
		    color: var(--color-white-inverted);
		}
	  }
	}
}
