@import "variables";
@import "mixins";

.content.theme-2 {
	h1 {
		color: var(--basic-color-blue-dark);
		font-size: 46px;
		line-height: 50px;
		font-weight: 700;
		letter-spacing: 6px;
		text-transform: var(--no-text-transform);
		margin: 0;

		@include media-query-sm {
			letter-spacing: 2px;
			font-size: 36px;
			line-height: 40px;
		}
	}

	h2, h3 {
		color: var(--basic-color-blue-light);
		font-size: 18px;
		margin-top: 0;
		text-align: right;

		&.center {
			text-align: center;
		}

		@include media-query-sm {
			text-align: left;
		}

		@include media-query-md {
			&.left-md {
				text-align: left;
			}
		}
	}

	.img-fluid {
		max-width: 100%;
	}

	.title {
		padding-top: 75px;
		padding-bottom: 75px;
		text-align: center;

		img {
			max-height: 50px;
		}

		@include media-query-md {
			padding-top: 50px;
			padding-bottom: 30px;
		}

		@include media-query-sm {
			img {
				max-height: 40px;
			}
		}
	}

	.content-block {
		padding-bottom: 1.5rem;

		@include media-query-sm {
			padding-bottom: 20px;
		}

		&.video {
			text-align: center;

			iframe {
				width: 1140px;
				height: 552px;

				@include media-query-lg {
					width: 940px;
					height: 455px;
				}

				@include media-query-md {
					width: 720px;
					height: 348px;
				}

				@include media-query-sm {
					width: 100%;
					height: 300px;
				}
			}
		}
	}

	.extendable-content-container {
		.content-wrapper, .content-wrapper p {
			line-height: 22px;
			max-height: 6 * 22px;
			overflow: hidden;
			transition: max-height 0.3s ease-out 0s;

			@include media-query-sm {
				line-height: 20px;
				max-height: 6 * 20px;
			}
		}

		> div {
			text-align: center;
			margin-top: 15px;

			button {
				font-size: 12px !important;
				background-color: transparent;
				border: none;

				&:active, &:focus {
					border: none;
				}
			}

			i.fa {
				display: inline-block;
				font-size: 22px;
				line-height: 30px;
				color: var(--basic-color-blue-light);
				margin-top: -5px;
			}
		}

		&.extended .content-wrapper p, &.extended .content-wrapper {
			max-height: none;
		}
	}

	.background-blue {
		background-color: var(--basic-color-blue-light);
		color: var(--color-white-bg);
	}

	.text-blue-dark {
		color: var(--basic-color-blue-dark) !important;
	}

	.text-dark-opacity {
		color: transparentize($color: #000, $amount: 0.5);
	}

	.options-list {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
		text-align: center;

		h4 {
			text-transform: lowercase;
			text-align: left;
			font-size: 14px;
			margin: 0 0 15px 0;
		}

		p {
			margin-bottom: 22px;

			a, a:hover, a:active, a:focus {
				color: var(--text-white);
				text-decoration: var(--no-text-decoration);
			}
		}

		&.no-title {
			padding-top: 40px;
			padding-bottom: 40px;

			p {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		@include media-query-sm {
			padding-top: 20px;
			padding-bottom: 20px;

			p {
				margin-bottom: 18px;
			}

			&.no-title {
				padding-top: 2rem;
				padding-bottom: 2rem;

				p {
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	.social-plugin {
		margin-bottom: 2rem;
		text-align: center;

		&.fb {
			padding-top: 0;
			text-align: center;

			> div {
				width: 500px;
				display: inline-block;

				@include media-query-sm {
					width: 420px;
				}

				@include media-query-xs {
					width: 340px;
				}

				@include media-query-xxs {
					width: 220px;
				}
			}
		}

		@include media-query-sm {
			margin-bottom: 20px;
		}
	}

	.note {
		text-align: center;

		h6 {
			font-size: 14px;
			color: var(--basic-color-blue-light);
			text-transform: uppercase;
			margin: 0;
		}

		p {
			font-size: 14px !important;
		}

		a {
			color: #333;
			text-decoration: var(--no-text-decoration);
			font-size: 14px !important;
		}
	}

	.read-more {
		text-align: center;
		padding-top: 10px;
		padding-bottom: 20px;
		display: none;

		button {
			font-size: 12px !important;
			background-color: transparent;
			border: none;

			&:active, &:focus {
				border: none;
			}
		}

		i {
			display: inline-block;
			font-size: 22px;
			line-height: 30px;
			color: var(--basic-color-blue-light);
			margin-top: -5px;
		}

		@include media-query-sm {
			display: block;
		}
	}

	.exendable-content {
		@include media-query-sm {
			max-height: 110px;
			overflow: hidden;

			&.extended {
				max-height: unset;
			}
		}
	}

	.text-notice {
		text-align: center;
		font-size: 36px;
		line-height: 50px;
		font-weight: 300;
		letter-spacing: 2px;
		text-transform: var(--no-text-transform);
		margin-bottom: 0;

		strong {
			font-weight: 700;
		}

		&.smaller {
			font-size: 30px;
			line-height: 38px;
			letter-spacing: 1px;
		}

		@media only screen and (max-width: 550px) {
			font-size: 22px;
			line-height: 28px;
			letter-spacing: 1px;

			&.smaller {
				font-size: 20px;
				line-height: 25px;
			}
		}
	}

	.background-theme {
		background-color: var(--basic-color-blue-dark);
		color: #fff;
	}

	.top-banner {
		height: 450px;
		position: relative;
		background-color: var(--color-black);
		background-position: bottom center;
		background-repeat: no-repeat;

		.layer {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background-color: transparentize(#000, 0.6);
			text-align: center;

			.title {
				bottom: 0;
				left: 0;
				right: 0;
				margin: 0 0 45px 0;
				padding-top: 0;
				padding-bottom: 0;
				text-align: center;
				position: absolute;

        .title-bg {
          display: inline-block;
          background-color: var(--background-color-transparent-monochromatic-enabled-inverse);
        }

				h1 {
					font-weight: 400;
					text-transform: var(--no-text-transform);
					font-size: 50px;
					margin: 0 0 5px 0;
					color: var(--color-white);
				}

				h2 {
					font-weight: 700;
					font-size: 46px;
					text-transform: uppercase;
					margin: 0;
					color: #fff;
				}
			}
		}

		@include media-query-sm {
			height: 220px;

			.layer {
				.title {
					margin: 0 0 20px 0;

					h1 {
						font-size: 25px;
					}

					h2 {
						font-size: 24px;
					}
				}
			}
		}
	}

	.btn.btn-cta {
		background: var(--basic-color-blue-light);
		color: var(--color-white-inverted);
		font-weight: 700;
		font-size: 22px;
		line-height: 24px;
		border-color: var(--basic-color-blue-light);
		border-radius: 10px;
		padding-left: 3rem;
		padding-right: 3rem;

		&.btn-w100 {
			width: 100%;
		}
	}
}
