@import "variables";
@import "mixins";

.content {
  min-height: 500px;

  p.small-font, p.small {
    font-size: 14px !important;
    margin-left: 10px !important;
    color: var(--text-color-gray-5);

    a {
      font-size: 14px !important;
    }
  }

  table.table.small {
    width: auto;
    min-width: 350px;

    @include media-query-xs {
      min-width: none;
      width: 100%;
    }
  }

  .top-background {
    position: relative;
    height: 350px;
    background-color: var(--color-black);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    .subpage-title {
      position: absolute;
      width: $container-xl;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      bottom: 20px;

      @include media-query-lg {
        width: $container-md;
      }

      @include media-query-md {
        width: $container-sm;
      }

      @include media-query-sm {
        width: 100%;
      }

      h1 {
        text-transform: var(--no-text-transform);
        color: var(--color-white);
        background: var(--basic-color-blue-dark);
        display: inline-block;
        padding: 10px 30px;
        font-size: 36px;
      }
    }

    @include media-query-lg {
      height: 300px;
    }

    @include media-query-md {
      height: 260px;
    }

    @include media-query-sm {
      height: 220px;
    }

    @media only screen and (max-width: 550px) {
      background-image: none !important;
      height: auto;

      .subpage-title {
        position: static;

        h1 {
          margin: 0;
          width: 100%;
          background: var(--basic-color-blue-dark);
          padding: 20px 10px 10px 20px;
        }
      }
    }
  }

  .subpage-title-no-background {
    h2 {
      text-transform: var(--no-text-transform);
      font-size: 32px;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }

  a, button {
    &.povprasevanje {
      color: var(--color-white);
      background: var(--basic-color-blue-dark);
      border: none;
      padding: 10px 15px;
      transition: background 0.2s;

      &:hover, &:active, &:focus {
        color: var(--color-white-inverted);
        background: var(--basic-color-blue-light);
        border: none;
        text-decoration: var(--no-text-decoration);
      }
    }
  }

  .inline-block {
    display: inline-block;
  }

  button, a {
    &.razsiri {
      color: var(--basic-color-blue-dark-text);
      background: transparent;
      border: none;
      padding: 10px 15px;
      margin-left: 20px;
      transition: color 0.2s;

      &:hover, &:active, &:focus {
        color: var(--basic-color-blue-light);
        border: none;
        text-decoration: var(--no-text-decoration);
      }
    }
  }

  .description {
    padding: 20px 0;

    &.no-top-padding {
      padding-top: 0;
    }

    h2 {
      font-size: 28px;
      text-transform: var(--no-text-transform);
      margin-bottom: 30px;
      color: var(--text-color-gray-5);
      font-weight: 600;
    }

    h3 {
      font-size: 20px;
      font-weight: 600;
      color: var(--text-color-gray-5);
      margin-bottom: 10px;
    }

    h4 {
      font-size: 16px;
      margin-top: 20px;
      font-weight: 600;
    }

    span.tick {
      display: inline-block;
      margin-right: 5px;
      width: 13px;
      height: 10px;
      background: var(--tick-icon-gray);
    }

    .cms-content {
      table tr td ul {
        margin-top: 0;
        margin-bottom: 0;

        li {
          margin-bottom: 0;
        }
      }
    }

    .infografika {

      img {
        max-height: 300px;
      }

      @include media-query-md {
        display: none;
      }
    }

    .ctas {
      border-top: 2px solid var(--basic-color-blue-dark-border);
      margin-top: 40px;
      margin-bottom: 20px;
      padding-top: 10px;

      div {
        display: inline-block;

        p {
          margin-top: 10px;
        }
      }

      button, a {
        display: inline-block;
        float: right;
      }
    }
  }

  ul {
    list-style-image: var(--tick-icon-gray);
    padding-left: 30px;
    margin-bottom: 20px;

    li {
      margin-bottom: 7px;
    }

    ul {
      list-style-image: none;
      list-style-type: disc;

      li {
        margin-bottom: 1px;
        color: var(--text-color-gray-5);
      }
    }
  }

  .akcija {
    padding-top: 15px;
    padding-bottom: 0;

    &.border-bottom {
      border-bottom-color: var(--text-color-gray-6);
    }

    &.narocilo {
      border: 1px solid var(--navbar-background);
      margin-bottom: 20px;
      padding: 0 30px 15px 30px;

      .arrow-down {
        margin-left: -30px;
        margin-right: -30px;

        i {
          color: #fafafa;
          left: 20%;
        }
      }

      &.details {
        margin-top: 20px;
        margin-bottom: 0;
      }

      h2, h3 {
        font-size: 22px;
        margin-bottom: 20px;
      }

      .btns {
        .razsiri {
          margin-left: 10px;
        }

        &.expanded {
          margin-bottom: 10px;
        }
      }

      .dodatne-informacije {
        padding: 0 30px;
      }
    }

    .ordering {
      display: flex;

      @include media-query-md {
        display: block;
      }

      .left {
        order: 1;
      }

      .right {
        order: 2;
      }

      .infografika {
        @include media-query-sm {
          display: none;
        }
      }
    }

    h2, h3 {
      color: var(--basic-color-blue-dark);
      margin-top: 15px;
      margin-bottom: 40px;
      font-weight: 600;
      text-transform: var(--no-text-transform);
    }

    img {
      margin: 15px 0;
    }

    .ctas {
      margin-top: 40px;
      margin-bottom: 20px;
    }
  }

  .dodatne-informacije {
    padding-top: 10px;
    padding-bottom: 30px;

    &.background-light-grey.dark  {
      background-color: var(--navbar-background) !important;
    }
  }

  .povezani-produkti {
    padding-top: 30px;
    padding-bottom: 20px;

    h2 {
      margin-left: 15px;
      font-size: 24px;
      text-transform: var(--no-text-transform);
      margin-top: 0;
      margin-bottom: 30px;
      color: var(--text-color-gray-7);
      font-weight: 600;
    }

    h3 {
      margin-left: 15px;
      font-size: 24px;
      text-transform: var(--no-text-transform);
      margin-top: 0;
      margin-bottom: 30px;
      color: var(--text-color-gray-7);
      font-weight: 600;
    }

    .povezan-produkt {
      background-color: var(--color-white-bg);
      border: 1px solid var(--color-border-gray-2);
      position: relative;
      height: 290px;

      @include media-query-md {
        height: auto;
        padding: 10px;
        margin-bottom: 10px;
      }

      .top {
        position: absolute;
        top: 20px;
        left: 15px;
        right: 15px;

        @include media-query-md {
          position: static;
        }

        .heading h3 {
          font-size: 22px;
          padding-bottom: 5px;

          margin-bottom: 0;
          margin-left: 0;
          font-weight: 400;

          @include media-query-md {
            font-size: 20px;
          }

          @include media-query-sm {
            font-size: 18px;
          }
        }

        .heading h4 {
          font-size: 22px;
          padding-bottom: 5px;

          @include media-query-md {
            font-size: 20px;
          }

          @include media-query-sm {
            font-size: 18px;
          }
        }
      }

      .bottom {
        position: absolute;
        left: 15px;
        right: 15px;
        bottom: 20px;

        @include media-query-md {
          position: static;
          height: 35px;
        }

        .image {
          display: inline-block;
          float: left;
          width: 100px;
          height: 100px;

          @include media-query-md {
            display: none;
          }

          img {
            width: 100px;
            height: 100px;
          }
        }

        .ctas {
          width: 150px;
          margin-top: 60px;
          float: right;
          display: inline-block;
          text-align: right;

          @include media-query-md {
            display: block;
            float: left;
            text-align: left;
            margin-top: 0;
          }

          .vec-o {
            display: inline-block;
            background-color: var(--color-gray-1-bg);
            border: 1px solid var(--color-border-gray-2);
            padding: 5px 10px;
            transition: background-color 0.2s;

            .icon {
              color: var(--basic-color-blue-dark);
            }

            &:hover, &:active, &:focus {
              background-color: var(--color-white-bg);
            }
          }
        }
      }
    }
  }
}
