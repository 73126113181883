.theme-wcag {

  --outline-color-main: rgb(0, 95, 204); // user agent color
  --outline-color-inverse: #fff; // inverse, use on links in main color bg

  --no-text-decoration: none;
  --no-text-transform: var(--no-text-transform);

  --color-white: #fff;
  --color-yellow-dark: #ffcd02;
  --color-light-gray: #fafafa;
  --color-black: #000;

  --color-white-inverted: #fff;
  --color-white-reverse-on-dark: #fff;
  --color-white-bg: #fff;
  --color-white-t30: rgba(255, 255, 255, 0.7);
  --color-white-t15: rgba(255, 255, 255, 0.85);

  --color-black-border-t875: rgba(0, 0, 0, 0.125);

  --color-brown-dark: #6d6168;
  --color-brown-dark-bg: #6d6168;

  // Additional colors
  --color-text: #646c73;
  --color-bright: #f7f9fa;
  --color-background: #edf0f5;
  --border-color: #e4e5e7;
  --bottom-menu-color: #0066b3;
  --error-color: #CC1212;

  // Energetika colors
  --basic-color-blue-dark: #0066b3;
  --basic-color-blue-light: #075f85;
  --basic-color-yellow: #F2DD20;
  --basic-color-green: #b9cdb2;
  --basic-color-green-darker: #5cb85c;
  --basic-color-background-color: #6693df;
  --zvestoba-ogreva-color: #dc5f55;

  --klub-zvestoba-custom-button: #fff;

  --basic-color-blue-dark-text: #003e6e;
  --basic-color-blue-dark-border: #0066b3;

  --basic-color-green-t35: rgba(185, 205, 178, 0.65);
  --basic-color-blue-dark-t80: rgba(0, 102, 179, 0.2);
  --basic-color-blue-dark-t45: rgba(0, 102, 179, 0.55);
  --basic-color-blue-dark-t40: rgba(0, 32, 89, 0.65);
  --basic-color-blue-dark-t35: rgba(0, 102, 179, 0.65);
  --basic-color-blue-dark-t25: rgba(0, 102, 179, 0.75);
  --basic-color-blue-dark-t20: rgba(2, 89, 140, 0.8);
  --basic-color-blue-dark-l30: #179bff; //lighten(#0066b3, 30%);
  --basic-color-blue-light-l30: #48c1f6; //lighten(#0BA1E2, 30%);
  --basic-color-yellow-t20: rgba(242, 221, 32, 0.8);
  --zvestoba-ogreva-color-t15: rgba(220, 95, 85, 0.85);

  //components
  --button-border-color-light: #e7e7e7;
  --button-border-color-gray: #ccc;
  --button-placeholder-color-text: rgba(85, 85, 85, 0.95);

  --color-border-gray-1: #d2d2d2;
  --color-border-gray-2: #d9d9d9;
  --color-border-gray-3: #f0f0f0;
  --color-border-gray-4: #ddd;
  --color-border-gray-5: #4c4c4c;

  --color-blue-bg-1: #0066b3;
  --color-blue-bg-2: #00508d;

  --color-light-red-bg: #f2685d;
  --color-light-red-bg-darkened-7: #f05245;

  --basic-color-blue-dark-button-bg: #0066b3;
  --basic-color-blue-dark-t20-button-bg: rgba(2, 89, 140, 0.8);
  --basic-color-blue-dark-t40-button-bg: rgba(0, 32, 89, 0.65);
  --basic-color-blue-dark-t20-bg: rgba(2, 89, 140, 0.8);
  --basic-color-blue-dark-t40-bg: rgba(0, 32, 89, 0.65);

  --border-none-monochromatic-enabled: none;
  --border-color-transparent-monochromatic-enabled: transparent;
  --background-color-transparent-monochromatic-enabled: transparent;
  --background-color-transparent-monochromatic-enabled-inverse: transparent;

  --background-stripes-nonchromatic: url("/assets/images/klub-zvestoba-ogreva/delimit-stripes.png");

  --navbar-background: #dadada;
  --navbar-border: #dadada;
  --navbar-color-shadow-black: rgba(0,0,0, .1);
  --navbar-color-shadow-white: rgba(255,255,255, .21);

  --navbar-dropdown-box-shadow: #707070;

  --slider-arrows-layer-bg: #0066B3;

  --header-bg: #fff;

  --footer-bg: #f1f1f1;
  --footer-color-gray: #353535;

  --body-color-bg: #f1f1f1;
  --body-color-text: #212529;

  --text-color-gray-1: #212529;
  --text-color-gray-2: #555;
  --text-color-gray-3: #333;
  --text-color-gray-4: #757575;
  --text-color-gray-5: #555555;
  --text-color-gray-6: #666666;
  --text-color-gray-7: #424242;

  --form-control-focus: #bababa;

  --input-background-color: #fff;
  --input-text-color: #495057;
  --input-placeholder-color: #a9a9a9;

  --input-error-icon-url: url("/assets/images/ikone/exclamation-circle-red.svg");
  --input-error-color: #ac0b0b;

  --dropdown-menu-border: rgba(0, 0, 0, 0.15);

  --icons-color-gray-1: #525252;
  --icons-fa-color-gray: #999;
  --icons-ul-li-fa-color: #888888;

  --nipke-icons-url: url("/assets/images/energetika-v-druzbi/energetika-nipke-priporoca-icons-sprite.png");
  --tick-icon-gray: url("/assets/images/general/wcag/li-tick-black.png");
  --tick-icon-green: url("/assets/images/general/li-tick-green.png");
  --tick-icon-input: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");

  --sidebar-nav-color-text: #767676;

  --table-color-gray-1-border: #dee2e6;
  --table-color-gray-1-text: #212529;
  --table-color-gray-1-bg: #f7f7f7;
  --table-color-caption-1: #6c757d;

  --news-color-text: #646464;
  --news-color-caption: #575757;

  --copyright-link-bg: #eee;

  --color-blue-light-bg: #0877a8;
  --color-gray-1-bg: #f5f5f5;

  --color-map-bg: #eeecea;
  --tt-blue-dark-bg:#2e5dad;
  --tt-blue-dark-text:#2e5dad;

  --povprasevanje-lighter-text-color: #666;
  --povprasevanje-main-color-t70: rgba(0, 92, 169, 0.85);
  --povprasevanje-main-color-t85: rgba(0, 92, 169, 0.15);
  --povprasevanje-main-color-bg-t70: rgba(0, 92, 169, 0.85);

  --razlaga-racuna-plin-color-bg: #ffd500;
  --razlaga-racuna-color-text: #575756;
  --razlaga-racuna-elektrika-color-bg: #366e42;
  --razlaga-racuna-elektrika-color-text: #366e42;
  --razlaga-racuna-toplota-color-bg: #71c5e2;
  --razlaga-racuna-toplota-color-bg2: #009fe3;
  --razlaga-racuna-box-shadow-color: grey;

  --tp-arrows-background: #0066b3;

  --left-arrow-carousel: url("/assets/images/ikone/left-arrow.svg");
  --right-arrow-carousel: url("/assets/images/ikone/right-arrow.svg");

  --mdc-switch-unselected-handle-color: var(--color-text);
  --mdc-switch-unselected-icon-color: var(--color-background);
  --mdc-switch-selected-handle-color: var(--color-text);
  --mdc-switch-selected-icon-color: var(--color-background);

  .mat-mdc-slide-toggle.mat-accent {
    --mdc-switch-selected-focus-state-layer-color: var(--color-text) !important;
    --mdc-switch-selected-handle-color: var(--color-text) !important;
    --mdc-switch-selected-hover-state-layer-color: var(--color-text) !important;
    --mdc-switch-selected-pressed-state-layer-color: var(--color-text) !important;

    --mdc-switch-selected-focus-handle-color: var(--color-text-dark) !important;
    --mdc-switch-selected-hover-handle-color: var(--color-text-dark) !important;
    --mdc-switch-selected-pressed-handle-color: var(--color-text-dark) !important;

    --mdc-switch-selected-focus-track-color: var(--color-text) !important;
    --mdc-switch-selected-hover-track-color: var(--color-text) !important;
    --mdc-switch-selected-pressed-track-color: var(--color-text) !important;
    --mdc-switch-selected-track-color: var(--color-text) !important;

    --mdc-switch-unselected-track-color: var(--color-text) !important;
    --mdc-switch-unselected-hover-handle-color: var(--color-text-dark) !important;
    --mdc-switch-unselected-hover-track-color: var(--color-text) !important;
    --mdc-switch-unselected-focus-handle-color: var(--color-text-dark) !important;
    --mdc-switch-unselected-focus-track-color: var(--color-text) !important;
  }
}


.theme-black-white {

  --outline-color-main: #000;
  --outline-color-inverse: #000;

  --color-1: #000;
  --color-1-dark: #000;
  --color-2: #fff;

  --color-white: var(--color-1);
  --color-yellow-dark: var(--color-2);
  --color-light-gray: var(--color-2);
  --color-black: var(--color-1);

  --color-white-inverted: var(--color-2);
  --color-white-reverse-on-dark: var(--color-1);
  --color-white-bg: var(--color-2);
  --color-white-t30: var(--color-1);
  --color-white-t15: var(--color-1);

  --color-black-border-t875: var(--color-1);

  --color-brown-dark: var(--color-1);
  --color-brown-dark-bg: var(--color-2);

  // Additional colors
  --color-text: var(--color-1);
  --color-text-dark: var(--color-1-dark);
  --color-bright: var(--color-1);
  --color-background: var(--color-2);
  --border-color: var(--color-1);
  --bottom-menu-color: var(--color-1);
  --error-color: var(--color-1);

  // Energetika colors
  --basic-color-blue-dark: var(--color-2);
  --basic-color-blue-light: var(--color-1);
  --basic-color-yellow: var(--color-2);
  --basic-color-green: var(--color-2);
  --basic-color-green-darker: var(--color-2);
  --basic-color-background-color: var(--color-2);
  --zvestoba-ogreva-color: var(--color-2);

  --basic-color-blue-dark-text: var(--color-1);
  --basic-color-blue-dark-border: var(--color-1);

  --basic-color-green-t35: var(--color-2);
  --basic-color-blue-dark-t80: var(--color-2);
  --basic-color-blue-dark-t45: var(--color-2);
  --basic-color-blue-dark-t40: var(--color-2);
  --basic-color-blue-dark-t35: var(--color-2);
  --basic-color-blue-dark-t25: var(--color-2);
  --basic-color-blue-dark-t20: var(--color-2);
  --basic-color-blue-dark-l30: var(--color-2);
  --basic-color-blue-light-l30: var(--color-2);
  --basic-color-yellow-t20: var(--color-2);
  --zvestoba-ogreva-color-t15: var(--color-2);

  --klub-zvestoba-custom-button: #fff;

  //components
  --button-border-color-light: var(--color-1);
  --button-border-color-gray: var(--color-1);
  --button-placeholder-color-text: var(--color-1);

  --color-border-gray-1: var(--color-1);
  --color-border-gray-2: var(--color-1);
  --color-border-gray-3: var(--color-1);
  --color-border-gray-4: var(--color-1);
  --color-border-gray-5: var(--color-1);

  --color-blue-bg-1: var(--color-2);
  --color-blue-bg-2: var(--color-1);

  --color-light-red-bg: var(--color-2);
  --color-light-red-bg-darkened-7: var(--color-1);

  --basic-color-blue-dark-button-bg: var(--color-1);
  --basic-color-blue-dark-t20-button-bg: var(--color-2);
  --basic-color-blue-dark-t40-button-bg: var(--color-2);
  --basic-color-blue-dark-t20-bg: var(--color-1);
  --basic-color-blue-dark-t40-bg: var(--color-1);

  --border-none-monochromatic-enabled: 2px solid var(--color-1);
  --border-color-transparent-monochromatic-enabled: var(--color-1);
  --background-color-transparent-monochromatic-enabled: var(--color-1);
  --background-color-transparent-monochromatic-enabled-inverse: var(--color-2);

  --background-stripes-nonchromatic: none;

  --navbar-border: var(--color-1);
  --navbar-background: var(--color-2);
  --navbar-color-shadow-black: var(--color-1);
  --navbar-color-shadow-white: var(--color-2);

  --navbar-dropdown-box-shadow: rgba(0, 0, 0, 0.16);

  --slider-arrows-layer-bg: var(--color-2);

  --header-bg: var(--color-2);

  --footer-bg: var(--color-2);
  --footer-color-gray: var(--color-1);

  --body-color-bg: var(--color-2);
  --body-color-text: var(--color-1);

  --text-color-gray-1: var(--color-1);
  --text-color-gray-2: var(--color-1);
  --text-color-gray-3: var(--color-1);
  --text-color-gray-4: var(--color-1);
  --text-color-gray-5: var(--color-1);
  --text-color-gray-6: var(--color-1);
  --text-color-gray-7: var(--color-1);

  --form-control-focus: var(--color-1);

  --input-background-color: var(--color-2);
  --input-text-color: var(--color-1);
  --input-placeholder-color: #999;

  --input-error-icon-url: url("/assets/images/ikone/exclamation-circle-black.svg");
  --input-error-color: var(--color-1);

  --dropdown-menu-border: var(--color-1);

  --icons-color-gray-1: var(--color-1);
  --icons-fa-color-gray: var(--color-1);
  --icons-ul-li-fa-color: var(--color-1);

  --nipke-icons-url: url("/assets/images/energetika-v-druzbi/energetika-nipke-priporoca-icons-sprite-black-white.png");
  --tick-icon-gray: url("/assets/images/general/wcag/li-tick-black.png");
  --tick-icon-green: url("/assets/images/general/wcag/li-tick-black.png");
  --tick-icon-input: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");

  --sidebar-nav-color-text: var(--color-1);

  --table-color-gray-1-text: var(--color-1);
  --table-color-gray-1-border: var(--color-1);
  --table-color-gray-1-bg: var(--color-2);
  --table-color-caption-1: var(--color-1);

  --news-color-text: var(--color-1);
  --news-color-caption: var(--color-1);

  --copyright-link-bg: var(--color-2);

  --color-blue-light-bg: var(--color-2);
  --color-gray-1-bg: var(--color-2);

  --color-map-bg: var(--color-2);

  --tt-blue-dark-bg: var(--color-2);
  --tt-blue-dark-text: var(--color-1);

  --povprasevanje-lighter-text-color: var(--color-1);
  --povprasevanje-main-color-t70: var(--color-1);
  --povprasevanje-main-color-t85: var(--color-2);
  --povprasevanje-main-color-bg-t70: var(--color-2);

  --razlaga-racuna-plin-color-bg: var(--color-2);
  --razlaga-racuna-color-text: var(--color-1);
  --razlaga-racuna-elektrika-color-bg: var(--color-2);
  --razlaga-racuna-elektrika-color-text: var(--color-1);
  --razlaga-racuna-toplota-color-bg: var(--color-2);
  --razlaga-racuna-toplota-color-bg2: var(--color-2);
  --razlaga-racuna-box-shadow-color: var(--color-1);

  --tp-arrows-background: var(--color-2);

  --left-arrow-carousel: url("/assets/images/ikone/wcag/left-arrow-black.svg");
  --right-arrow-carousel: url("/assets/images/ikone/wcag/right-arrow-black.svg");

  --mdc-switch-unselected-handle-color: var(--color-text);
  --mdc-switch-unselected-icon-color: var(--color-background);
  --mdc-switch-selected-handle-color: var(--color-text);
  --mdc-switch-selected-icon-color: var(--color-background);

  .mat-mdc-slide-toggle.mat-accent {
    --mdc-switch-selected-focus-state-layer-color: var(--color-text) !important;
    --mdc-switch-selected-handle-color: var(--color-text) !important;
    --mdc-switch-selected-hover-state-layer-color: var(--color-text) !important;
    --mdc-switch-selected-pressed-state-layer-color: var(--color-text) !important;

    --mdc-switch-selected-focus-handle-color: var(--color-text-dark) !important;
    --mdc-switch-selected-hover-handle-color: var(--color-text-dark) !important;
    --mdc-switch-selected-pressed-handle-color: var(--color-text-dark) !important;

    --mdc-switch-selected-focus-track-color: var(--color-text) !important;
    --mdc-switch-selected-hover-track-color: var(--color-text) !important;
    --mdc-switch-selected-pressed-track-color: var(--color-text) !important;
    --mdc-switch-selected-track-color: var(--color-text) !important;

    --mdc-switch-unselected-track-color: var(--color-text) !important;
    --mdc-switch-unselected-hover-handle-color: var(--color-text-dark) !important;
    --mdc-switch-unselected-hover-track-color: var(--color-text) !important;
    --mdc-switch-unselected-focus-handle-color: var(--color-text-dark) !important;
    --mdc-switch-unselected-focus-track-color: var(--color-text) !important;
  }
}

.theme-white-black {

  --outline-color-main: #fff;
  --outline-color-inverse: #fff;

  --color-1: #fff;
  --color-1-dark: #efefef;
  --color-2: #000;

  --color-white: var(--color-1);
  --color-yellow-dark: var(--color-2);
  --color-light-gray: var(--color-2);
  --color-black: var(--color-1);

  --color-white-inverted: var(--color-2);
  --color-white-reverse-on-dark: var(--color-1);
  --color-white-bg: var(--color-2);
  --color-white-t30: var(--color-1);
  --color-white-t15: var(--color-1);

  --color-black-border-t875: var(--color-1);

  --color-brown-dark: var(--color-1);
  --color-brown-dark-bg: var(--color-2);

  // Additional colors
  --color-text: var(--color-1);
  --color-text-dark: var(--color-1-dark);
  --color-bright: var(--color-1);
  --color-background: var(--color-2);
  --border-color: var(--color-1);
  --bottom-menu-color: var(--color-1);
  --error-color: var(--color-1);

  // Energetika colors
  --basic-color-blue-dark: var(--color-2);
  --basic-color-blue-light: var(--color-1);
  --basic-color-yellow: var(--color-2);
  --basic-color-green: var(--color-2);
  --basic-color-green-darker: var(--color-2);
  --basic-color-background-color: var(--color-2);
  --zvestoba-ogreva-color: var(--color-2);

  --klub-zvestoba-custom-button: #fff;

  --basic-color-blue-dark-text: var(--color-1);
  --basic-color-blue-dark-border: var(--color-1);

  --basic-color-green-t35: var(--color-2);
  --basic-color-blue-dark-t80: var(--color-2);
  --basic-color-blue-dark-t45: var(--color-2);
  --basic-color-blue-dark-t40: var(--color-2);
  --basic-color-blue-dark-t35: var(--color-2);
  --basic-color-blue-dark-t25: var(--color-2);
  --basic-color-blue-dark-t20: var(--color-2);
  --basic-color-blue-dark-l30: var(--color-2);
  --basic-color-blue-light-l30: var(--color-2);
  --basic-color-yellow-t20: var(--color-2);
  --zvestoba-ogreva-color-t15: var(--color-2);

  //components
  --button-border-color-light: var(--color-1);
  --button-border-color-gray: var(--color-1);
  --button-placeholder-color-text: var(--color-1);

  --color-border-gray-1: var(--color-1);
  --color-border-gray-2: var(--color-1);
  --color-border-gray-3: var(--color-1);
  --color-border-gray-4: var(--color-1);
  --color-border-gray-5: var(--color-1);

  --color-blue-bg-1: var(--color-2);
  --color-blue-bg-2: var(--color-1);

  --color-light-red-bg: var(--color-2);
  --color-light-red-bg-darkened-7: var(--color-1);

  --basic-color-blue-dark-button-bg: var(--color-1);
  --basic-color-blue-dark-t20-button-bg: var(--color-2);
  --basic-color-blue-dark-t40-button-bg: var(--color-2);
  --basic-color-blue-dark-t20-bg: var(--color-1);
  --basic-color-blue-dark-t40-bg: var(--color-1);

  --border-none-monochromatic-enabled: 2px solid var(--color-1);
  --border-color-transparent-monochromatic-enabled: var(--color-1);
  --background-color-transparent-monochromatic-enabled: var(--color-1);
  --background-color-transparent-monochromatic-enabled-inverse: var(--color-2);

  --background-stripes-nonchromatic: none;

  --navbar-border: var(--color-1);
  --navbar-background: var(--color-2);
  --navbar-color-shadow-black: var(--color-1);
  --navbar-color-shadow-white: var(--color-2);

  --navbar-dropdown-box-shadow: rgba(255, 255, 255, 0.46);

  --slider-arrows-layer-bg: var(--color-2);

  --header-bg: var(--color-2);

  --footer-bg: var(--color-2);
  --footer-color-gray: var(--color-1);

  --body-color-bg: var(--color-2);
  --body-color-text: var(--color-1);

  --text-color-gray-1: var(--color-1);
  --text-color-gray-2: var(--color-1);
  --text-color-gray-3: var(--color-1);
  --text-color-gray-4: var(--color-1);
  --text-color-gray-5: var(--color-1);
  --text-color-gray-6: var(--color-1);
  --text-color-gray-7: var(--color-1);

  --form-control-focus: var(--color-1);

  --input-background-color: var(--color-2);
  --input-text-color: var(--color-1);
  --input-placeholder-color: #999;

  --input-error-icon-url: url("/assets/images/ikone/exclamation-circle-white.svg");
  --input-error-color: var(--color-1);

  --dropdown-menu-border: var(--color-1);

  --icons-color-gray-1: var(--color-1);
  --icons-fa-color-gray: var(--color-1);
  --icons-ul-li-fa-color: var(--color-1);

  --nipke-icons-url: url("/assets/images/energetika-v-druzbi/energetika-nipke-priporoca-icons-sprite-white-black.png");
  --tick-icon-gray: url("/assets/images/general/wcag/li-tick-white.png");
  --tick-icon-green: url("/assets/images/general/wcag/li-tick-white.png");
  --tick-icon-input: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");

  --sidebar-nav-color-text: var(--color-1);

  --table-color-gray-1-text: var(--color-1);
  --table-color-gray-1-border: var(--color-1);
  --table-color-gray-1-bg: var(--color-2);
  --table-color-caption-1: var(--color-1);

  --news-color-text: var(--color-1);
  --news-color-caption: var(--color-1);

  --copyright-link-bg: var(--color-2);

  --color-blue-light-bg: var(--color-2);
  --color-gray-1-bg: var(--color-2);

  --color-map-bg: var(--color-2);

  --tt-blue-dark-bg: var(--color-2);
  --tt-blue-dark-text: var(--color-1);

  --povprasevanje-lighter-text-color: var(--color-1);
  --povprasevanje-main-color-t70: var(--color-1);
  --povprasevanje-main-color-t85: var(--color-2);
  --povprasevanje-main-color-bg-t70: var(--color-2);

  --razlaga-racuna-plin-color-bg: var(--color-2);
  --razlaga-racuna-color-text: var(--color-1);
  --razlaga-racuna-elektrika-color-bg: var(--color-2);
  --razlaga-racuna-elektrika-color-text: var(--color-1);
  --razlaga-racuna-toplota-color-bg: var(--color-2);
  --razlaga-racuna-toplota-color-bg2: var(--color-2);
  --razlaga-racuna-box-shadow-color: var(--color-1);

  --tp-arrows-background: var(--color-2);

  --left-arrow-carousel: url("/assets/images/ikone/left-arrow.svg");
  --right-arrow-carousel: url("/assets/images/ikone/right-arrow.svg");

  --mdc-switch-unselected-handle-color: var(--color-text);
  --mdc-switch-unselected-icon-color: var(--color-background);
  --mdc-switch-selected-handle-color: var(--color-text);
  --mdc-switch-selected-icon-color: var(--color-background);

  .mat-mdc-slide-toggle.mat-accent {
    --mdc-switch-selected-focus-state-layer-color: var(--color-text) !important;
    --mdc-switch-selected-handle-color: var(--color-text) !important;
    --mdc-switch-selected-hover-state-layer-color: var(--color-text) !important;
    --mdc-switch-selected-pressed-state-layer-color: var(--color-text) !important;

    --mdc-switch-selected-focus-handle-color: var(--color-text-dark) !important;
    --mdc-switch-selected-hover-handle-color: var(--color-text-dark) !important;
    --mdc-switch-selected-pressed-handle-color: var(--color-text-dark) !important;

    --mdc-switch-selected-focus-track-color: var(--color-text) !important;
    --mdc-switch-selected-hover-track-color: var(--color-text) !important;
    --mdc-switch-selected-pressed-track-color: var(--color-text) !important;
    --mdc-switch-selected-track-color: var(--color-text) !important;

    --mdc-switch-unselected-track-color: var(--color-text) !important;
    --mdc-switch-unselected-hover-handle-color: var(--color-text-dark) !important;
    --mdc-switch-unselected-hover-track-color: var(--color-text) !important;
    --mdc-switch-unselected-focus-handle-color: var(--color-text-dark) !important;
    --mdc-switch-unselected-focus-track-color: var(--color-text) !important;
  }
}

.theme-black-beige {

  --outline-color-main: #000;
  --outline-color-inverse: #000;

  --color-1: #000;
  --color-1-dark: #000;
  --color-2: beige;

  --color-white: var(--color-1);
  --color-yellow-dark: var(--color-2);
  --color-light-gray: var(--color-2);
  --color-black: var(--color-1);

  --color-white-inverted: var(--color-2);
  --color-white-reverse-on-dark: var(--color-1);
  --color-white-bg: var(--color-2);
  --color-white-t30: var(--color-1);
  --color-white-t15: var(--color-1);

  --color-black-border-t875: var(--color-1);

  --color-brown-dark: var(--color-1);
  --color-brown-dark-bg: var(--color-2);

  // Additional colors
  --color-text: var(--color-1);
  --color-text-dark: var(--color-1-dark);
  --color-bright: var(--color-1);
  --color-background: var(--color-2);
  --border-color: var(--color-1);
  --bottom-menu-color: var(--color-1);
  --error-color: var(--color-1);

  // Energetika colors
  --basic-color-blue-dark: var(--color-2);
  --basic-color-blue-light: var(--color-1);
  --basic-color-yellow: var(--color-2);
  --basic-color-green: var(--color-2);
  --basic-color-green-darker: var(--color-2);
  --basic-color-background-color: var(--color-2);
  --zvestoba-ogreva-color: var(--color-2);

  --klub-zvestoba-custom-button: #fff;

  --basic-color-blue-dark-text: var(--color-1);
  --basic-color-blue-dark-border: var(--color-1);

  --basic-color-green-t35: var(--color-2);
  --basic-color-blue-dark-t80: var(--color-2);
  --basic-color-blue-dark-t45: var(--color-2);
  --basic-color-blue-dark-t40: var(--color-2);
  --basic-color-blue-dark-t35: var(--color-2);
  --basic-color-blue-dark-t25: var(--color-2);
  --basic-color-blue-dark-t20: var(--color-2);
  --basic-color-blue-dark-l30: var(--color-2);
  --basic-color-blue-light-l30: var(--color-2);
  --basic-color-yellow-t20: var(--color-2);
  --zvestoba-ogreva-color-t15: var(--color-2);

  //components
  --button-border-color-light: var(--color-1);
  --button-border-color-gray: var(--color-1);
  --button-placeholder-color-text: var(--color-1);

  --color-border-gray-1: var(--color-1);
  --color-border-gray-2: var(--color-1);
  --color-border-gray-3: var(--color-1);
  --color-border-gray-4: var(--color-1);
  --color-border-gray-5: var(--color-1);

  --color-blue-bg-1: var(--color-2);
  --color-blue-bg-2: var(--color-1);

  --color-light-red-bg: var(--color-2);
  --color-light-red-bg-darkened-7: var(--color-1);

  --basic-color-blue-dark-button-bg: var(--color-1);
  --basic-color-blue-dark-t20-button-bg: var(--color-2);
  --basic-color-blue-dark-t40-button-bg: var(--color-2);
  --basic-color-blue-dark-t20-bg: var(--color-1);
  --basic-color-blue-dark-t40-bg: var(--color-1);

  --border-none-monochromatic-enabled: 2px solid var(--color-1);
  --border-color-transparent-monochromatic-enabled: var(--color-1);
  --background-color-transparent-monochromatic-enabled: var(--color-1);
  --background-color-transparent-monochromatic-enabled-inverse: var(--color-2);

  --background-stripes-nonchromatic: none;

  --navbar-border: var(--color-1);
  --navbar-background: var(--color-2);
  --navbar-color-shadow-black: var(--color-1);
  --navbar-color-shadow-white: var(--color-2);

  --navbar-dropdown-box-shadow: rgba(0, 0, 0, 0.16);

  --slider-arrows-layer-bg: var(--color-2);

  --header-bg: var(--color-2);

  --footer-bg: var(--color-2);
  --footer-color-gray: var(--color-1);

  --body-color-bg: var(--color-2);
  --body-color-text: var(--color-1);

  --text-color-gray-1: var(--color-1);
  --text-color-gray-2: var(--color-1);
  --text-color-gray-3: var(--color-1);
  --text-color-gray-4: var(--color-1);
  --text-color-gray-5: var(--color-1);
  --text-color-gray-6: var(--color-1);
  --text-color-gray-7: var(--color-1);

  --form-control-focus: var(--color-1);

  --input-background-color: var(--color-2);
  --input-text-color: var(--color-1);
  --input-placeholder-color: #6e6e6e;

  --input-error-icon-url: url("/assets/images/ikone/exclamation-circle-black.svg");
  --input-error-color: var(--color-1);

  --dropdown-menu-border: var(--color-1);

  --icons-color-gray-1: var(--color-1);
  --icons-fa-color-gray: var(--color-1);
  --icons-ul-li-fa-color: var(--color-1);

  --nipke-icons-url: url("/assets/images/energetika-v-druzbi/energetika-nipke-priporoca-icons-sprite-black-beige.png");
  --tick-icon-gray: url("/assets/images/general/wcag/li-tick-black.png");
  --tick-icon-green: url("/assets/images/general/wcag/li-tick-black.png");
  --tick-icon-input: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");

  --sidebar-nav-color-text: var(--color-1);

  --table-color-gray-1-text: var(--color-1);
  --table-color-gray-1-border: var(--color-1);
  --table-color-gray-1-bg: var(--color-2);
  --table-color-caption-1: var(--color-1);

  --news-color-text: var(--color-1);
  --news-color-caption: var(--color-1);

  --copyright-link-bg: var(--color-2);

  --color-blue-light-bg: var(--color-2);
  --color-gray-1-bg: var(--color-2);

  --color-map-bg: var(--color-2);

  --tt-blue-dark-bg: var(--color-2);
  --tt-blue-dark-text: var(--color-1);

  --povprasevanje-lighter-text-color: var(--color-1);
  --povprasevanje-main-color-t70: var(--color-1);
  --povprasevanje-main-color-t85: var(--color-2);
  --povprasevanje-main-color-bg-t70: var(--color-2);

  --razlaga-racuna-plin-color-bg: var(--color-2);
  --razlaga-racuna-color-text: var(--color-1);
  --razlaga-racuna-elektrika-color-bg: var(--color-2);
  --razlaga-racuna-elektrika-color-text: var(--color-1);
  --razlaga-racuna-toplota-color-bg: var(--color-2);
  --razlaga-racuna-toplota-color-bg2: var(--color-2);
  --razlaga-racuna-box-shadow-color: var(--color-1);

  --tp-arrows-background: var(--color-2);

  --left-arrow-carousel: url("/assets/images/ikone/wcag/left-arrow-black.svg");
  --right-arrow-carousel: url("/assets/images/ikone/wcag/right-arrow-black.svg");

  --mdc-switch-unselected-handle-color: var(--color-text);
  --mdc-switch-unselected-icon-color: var(--color-background);
  --mdc-switch-selected-handle-color: var(--color-text);
  --mdc-switch-selected-icon-color: var(--color-background);

  .mat-mdc-slide-toggle.mat-accent {
    --mdc-switch-selected-focus-state-layer-color: var(--color-text) !important;
    --mdc-switch-selected-handle-color: var(--color-text) !important;
    --mdc-switch-selected-hover-state-layer-color: var(--color-text) !important;
    --mdc-switch-selected-pressed-state-layer-color: var(--color-text) !important;

    --mdc-switch-selected-focus-handle-color: var(--color-text-dark) !important;
    --mdc-switch-selected-hover-handle-color: var(--color-text-dark) !important;
    --mdc-switch-selected-pressed-handle-color: var(--color-text-dark) !important;

    --mdc-switch-selected-focus-track-color: var(--color-text) !important;
    --mdc-switch-selected-hover-track-color: var(--color-text) !important;
    --mdc-switch-selected-pressed-track-color: var(--color-text) !important;
    --mdc-switch-selected-track-color: var(--color-text) !important;

    --mdc-switch-unselected-track-color: var(--color-text) !important;
    --mdc-switch-unselected-hover-handle-color: var(--color-text-dark) !important;
    --mdc-switch-unselected-hover-track-color: var(--color-text) !important;
    --mdc-switch-unselected-focus-handle-color: var(--color-text-dark) !important;
    --mdc-switch-unselected-focus-track-color: var(--color-text) !important;
  }
}

.theme-yellow-blue {

  --outline-color-main: #ffe800;
  --outline-color-inverse: #ffe800;

  --color-1: #ffe800;
  --color-1-dark: #d1ac00;
  --color-2: #00008b;

  --color-white: var(--color-1);
  --color-yellow-dark: var(--color-2);
  --color-light-gray: var(--color-2);
  --color-black: var(--color-1);

  --color-white-inverted: var(--color-2);
  --color-white-reverse-on-dark: var(--color-1);
  --color-white-bg: var(--color-2);
  --color-white-t30: var(--color-1);
  --color-white-t15: var(--color-1);

  --color-black-border-t875: var(--color-1);

  --color-brown-dark: var(--color-1);
  --color-brown-dark-bg: var(--color-2);

  // Additional colors
  --color-text: var(--color-1);
  --color-text-dark: var(--color-1-dark);
  --color-bright: var(--color-1);
  --color-background: var(--color-2);
  --border-color: var(--color-1);
  --bottom-menu-color: var(--color-1);
  --error-color: var(--color-1);

  // Energetika colors
  --basic-color-blue-dark: var(--color-2);
  --basic-color-blue-light: var(--color-1);
  --basic-color-yellow: var(--color-2);
  --basic-color-green: var(--color-2);
  --basic-color-green-darker: var(--color-2);
  --basic-color-background-color: var(--color-2);
  --zvestoba-ogreva-color: var(--color-2);

  --klub-zvestoba-custom-button: #fff;

  --basic-color-blue-dark-text: var(--color-1);
  --basic-color-blue-dark-border: var(--color-1);

  --basic-color-green-t35: var(--color-2);
  --basic-color-blue-dark-t80: var(--color-2);
  --basic-color-blue-dark-t45: var(--color-2);
  --basic-color-blue-dark-t40: var(--color-2);
  --basic-color-blue-dark-t35: var(--color-2);
  --basic-color-blue-dark-t25: var(--color-2);
  --basic-color-blue-dark-t20: var(--color-2);
  --basic-color-blue-dark-l30: var(--color-2);
  --basic-color-blue-light-l30: var(--color-2);
  --basic-color-yellow-t20: var(--color-2);
  --zvestoba-ogreva-color-t15: var(--color-2);

  //components
  --button-border-color-light: var(--color-1);
  --button-border-color-gray: var(--color-1);
  --button-placeholder-color-text: var(--color-1);

  --color-border-gray-1: var(--color-1);
  --color-border-gray-2: var(--color-1);
  --color-border-gray-3: var(--color-1);
  --color-border-gray-4: var(--color-1);
  --color-border-gray-5: var(--color-1);

  --color-blue-bg-1: var(--color-2);
  --color-blue-bg-2: var(--color-1);

  --color-light-red-bg: var(--color-2);
  --color-light-red-bg-darkened-7: var(--color-1);

  --basic-color-blue-dark-button-bg: var(--color-1);
  --basic-color-blue-dark-t20-button-bg: var(--color-2);
  --basic-color-blue-dark-t40-button-bg: var(--color-2);
  --basic-color-blue-dark-t20-bg: var(--color-1);
  --basic-color-blue-dark-t40-bg: var(--color-1);

  --border-none-monochromatic-enabled: 2px solid var(--color-1);
  --border-color-transparent-monochromatic-enabled: var(--color-1);
  --background-color-transparent-monochromatic-enabled: var(--color-1);
  --background-color-transparent-monochromatic-enabled-inverse: var(--color-2);

  --background-stripes-nonchromatic: none;

  --navbar-border: var(--color-1);
  --navbar-background: var(--color-2);
  --navbar-color-shadow-black: var(--color-1);
  --navbar-color-shadow-white: var(--color-2);

  --navbar-dropdown-box-shadow: rgba(255, 232, 0, 0.46);

  --slider-arrows-layer-bg: var(--color-2);

  --header-bg: var(--color-2);

  --footer-bg: var(--color-2);
  --footer-color-gray: var(--color-1);

  --body-color-bg: var(--color-2);
  --body-color-text: var(--color-1);

  --text-color-gray-1: var(--color-1);
  --text-color-gray-2: var(--color-1);
  --text-color-gray-3: var(--color-1);
  --text-color-gray-4: var(--color-1);
  --text-color-gray-5: var(--color-1);
  --text-color-gray-6: var(--color-1);
  --text-color-gray-7: var(--color-1);

  --form-control-focus: var(--color-1);

  --input-background-color: var(--color-2);
  --input-text-color: var(--color-1);
  --input-placeholder-color: #b9ad51; // lighten yellow 50%

  --input-error-icon-url: url("/assets/images/ikone/exclamation-circle-yellow.svg");
  --input-error-color: var(--color-1);
  --icons-ul-li-fa-color: var(--color-1);

  --nipke-icons-url: url("/assets/images/energetika-v-druzbi/energetika-nipke-priporoca-icons-sprite-yellow-blue.png");
  --tick-icon-gray: url("/assets/images/general/wcag/li-tick-yellow.png");
  --tick-icon-green: url("/assets/images/general/wcag/li-tick-yellow.png");
  --tick-icon-input: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffe800' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");

  --dropdown-menu-border: var(--color-1);

  --icons-color-gray-1: var(--color-1);
  --icons-fa-color-gray: var(--color-1);

  --sidebar-nav-color-text: var(--color-1);

  --table-color-gray-1-text: var(--color-1);
  --table-color-gray-1-border: var(--color-1);
  --table-color-gray-1-bg: var(--color-2);
  --table-color-caption-1: var(--color-1);

  --news-color-text: var(--color-1);
  --news-color-caption: var(--color-1);

  --copyright-link-bg: var(--color-2);

  --color-blue-light-bg: var(--color-2);
  --color-gray-1-bg: var(--color-2);

  --color-map-bg: var(--color-2);

  --tt-blue-dark-bg: var(--color-2);
  --tt-blue-dark-text: var(--color-1);

  --povprasevanje-lighter-text-color: var(--color-1);
  --povprasevanje-main-color-t70: var(--color-1);
  --povprasevanje-main-color-t85: var(--color-2);
  --povprasevanje-main-color-bg-t70: var(--color-2);

  --razlaga-racuna-plin-color-bg: var(--color-2);
  --razlaga-racuna-color-text: var(--color-1);
  --razlaga-racuna-elektrika-color-bg: var(--color-2);
  --razlaga-racuna-elektrika-color-text: var(--color-1);
  --razlaga-racuna-toplota-color-bg: var(--color-2);
  --razlaga-racuna-toplota-color-bg2: var(--color-2);
  --razlaga-racuna-box-shadow-color: var(--color-1);

  --tp-arrows-background: var(--color-2);

  --left-arrow-carousel: url("/assets/images/ikone/wcag/left-arrow-yellow.svg");
  --right-arrow-carousel: url("/assets/images/ikone/wcag/right-arrow-yellow.svg");

  --mdc-switch-unselected-handle-color: var(--color-text);
  --mdc-switch-unselected-icon-color: var(--color-background);
  --mdc-switch-selected-handle-color: var(--color-text);
  --mdc-switch-selected-icon-color: var(--color-background);

  .mat-mdc-slide-toggle.mat-accent {
    --mdc-switch-selected-focus-state-layer-color: var(--color-text) !important;
    --mdc-switch-selected-handle-color: var(--color-text) !important;
    --mdc-switch-selected-hover-state-layer-color: var(--color-text) !important;
    --mdc-switch-selected-pressed-state-layer-color: var(--color-text) !important;

    --mdc-switch-selected-focus-handle-color: var(--color-text-dark) !important;
    --mdc-switch-selected-hover-handle-color: var(--color-text-dark) !important;
    --mdc-switch-selected-pressed-handle-color: var(--color-text-dark) !important;

    --mdc-switch-selected-focus-track-color: var(--color-text) !important;
    --mdc-switch-selected-hover-track-color: var(--color-text) !important;
    --mdc-switch-selected-pressed-track-color: var(--color-text) !important;
    --mdc-switch-selected-track-color: var(--color-text) !important;

    --mdc-switch-unselected-track-color: var(--color-text) !important;
    --mdc-switch-unselected-hover-handle-color: var(--color-text-dark) !important;
    --mdc-switch-unselected-hover-track-color: var(--color-text) !important;
    --mdc-switch-unselected-focus-handle-color: var(--color-text-dark) !important;
    --mdc-switch-unselected-focus-track-color: var(--color-text) !important;
  }
}

.theme-black-yellow {

  --outline-color-main: #000;
  --outline-color-inverse: #000;

  --color-1: #000;
  --color-1-dark: #000;
  --color-2: #ffe800;

  --color-white: var(--color-1);
  --color-yellow-dark: var(--color-2);
  --color-light-gray: var(--color-2);
  --color-black: var(--color-1);

  --color-white-inverted: var(--color-2);
  --color-white-reverse-on-dark: var(--color-1);
  --color-white-bg: var(--color-2);
  --color-white-t30: var(--color-1);
  --color-white-t15: var(--color-1);

  --color-black-border-t875: var(--color-1);

  --color-brown-dark: var(--color-1);
  --color-brown-dark-bg: var(--color-2);

  // Additional colors
  --color-text: var(--color-1);
  --color-text-dark: var(--color-1-dark);
  --color-bright: var(--color-1);
  --color-background: var(--color-2);
  --border-color: var(--color-1);
  --bottom-menu-color: var(--color-1);
  --error-color: var(--color-1);

  // Energetika colors
  --basic-color-blue-dark: var(--color-2);
  --basic-color-blue-light: var(--color-1);
  --basic-color-yellow: var(--color-2);
  --basic-color-green: var(--color-2);
  --basic-color-green-darker: var(--color-2);
  --basic-color-background-color: var(--color-2);
  --zvestoba-ogreva-color: var(--color-2);

  --klub-zvestoba-custom-button: #fff;

  --basic-color-blue-dark-text: var(--color-1);
  --basic-color-blue-dark-border: var(--color-1);

  --basic-color-green-t35: var(--color-2);
  --basic-color-blue-dark-t80: var(--color-2);
  --basic-color-blue-dark-t45: var(--color-2);
  --basic-color-blue-dark-t40: var(--color-2);
  --basic-color-blue-dark-t35: var(--color-2);
  --basic-color-blue-dark-t25: var(--color-2);
  --basic-color-blue-dark-t20: var(--color-2);
  --basic-color-blue-dark-l30: var(--color-2);
  --basic-color-blue-light-l30: var(--color-2);
  --basic-color-yellow-t20: var(--color-2);
  --zvestoba-ogreva-color-t15: var(--color-2);

  //components
  --button-border-color-light: var(--color-1);
  --button-border-color-gray: var(--color-1);
  --button-placeholder-color-text: var(--color-1);

  --color-border-gray-1: var(--color-1);
  --color-border-gray-2: var(--color-1);
  --color-border-gray-3: var(--color-1);
  --color-border-gray-4: var(--color-1);
  --color-border-gray-5: var(--color-1);

  --color-blue-bg-1: var(--color-2);
  --color-blue-bg-2: var(--color-1);

  --color-light-red-bg: var(--color-2);
  --color-light-red-bg-darkened-7: var(--color-1);

  --basic-color-blue-dark-button-bg: var(--color-1);
  --basic-color-blue-dark-t20-button-bg: var(--color-2);
  --basic-color-blue-dark-t40-button-bg: var(--color-2);
  --basic-color-blue-dark-t20-bg: var(--color-1);
  --basic-color-blue-dark-t40-bg: var(--color-1);

  --border-none-monochromatic-enabled: 2px solid var(--color-1);
  --border-color-transparent-monochromatic-enabled: var(--color-1);
  --background-color-transparent-monochromatic-enabled: var(--color-1);
  --background-color-transparent-monochromatic-enabled-inverse: var(--color-2);

  --background-stripes-nonchromatic: none;

  --navbar-border: var(--color-1);
  --navbar-background: var(--color-2);
  --navbar-color-shadow-black: var(--color-1);
  --navbar-color-shadow-white: var(--color-2);

  --navbar-dropdown-box-shadow: rgba(0, 0, 0, 0.16);

  --slider-arrows-layer-bg: var(--color-2);

  --header-bg: var(--color-2);

  --footer-bg: var(--color-2);
  --footer-color-gray: var(--color-1);

  --body-color-bg: var(--color-2);
  --body-color-text: var(--color-1);

  --text-color-gray-1: var(--color-1);
  --text-color-gray-2: var(--color-1);
  --text-color-gray-3: var(--color-1);
  --text-color-gray-4: var(--color-1);
  --text-color-gray-5: var(--color-1);
  --text-color-gray-6: var(--color-1);
  --text-color-gray-7: var(--color-1);

  --form-control-focus: var(--color-1);

  --input-background-color: var(--color-2);
  --input-text-color: var(--color-1);
  --input-placeholder-color: #656565;

  --input-error-icon-url: url("/assets/images/ikone/exclamation-circle-black.svg");
  --input-error-color: var(--color-1);

  --dropdown-menu-border: var(--color-1);

  --icons-color-gray-1: var(--color-1);
  --icons-fa-color-gray: var(--color-1);
  --icons-ul-li-fa-color: var(--color-1);

  --nipke-icons-url: url("/assets/images/energetika-v-druzbi/energetika-nipke-priporoca-icons-sprite-black-yellow.png");
  --tick-icon-gray: url("/assets/images/general/wcag/li-tick-black.png");
  --tick-icon-green: url("/assets/images/general/wcag/li-tick-black.png");
  --tick-icon-input: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");

  --sidebar-nav-color-text: var(--color-1);

  --table-color-gray-1-text: var(--color-1);
  --table-color-gray-1-border: var(--color-1);
  --table-color-gray-1-bg: var(--color-2);
  --table-color-caption-1: var(--color-1);

  --news-color-text: var(--color-1);
  --news-color-caption: var(--color-1);

  --copyright-link-bg: var(--color-2);

  --color-blue-light-bg: var(--color-2);
  --color-gray-1-bg: var(--color-2);

  --color-map-bg: var(--color-2);

  --tt-blue-dark-bg: var(--color-2);
  --tt-blue-dark-text: var(--color-1);

  --povprasevanje-lighter-text-color: var(--color-1);
  --povprasevanje-main-color-t70: var(--color-1);
  --povprasevanje-main-color-t85: var(--color-2);
  --povprasevanje-main-color-bg-t70: var(--color-2);

  --razlaga-racuna-plin-color-bg: var(--color-2);
  --razlaga-racuna-color-text: var(--color-1);
  --razlaga-racuna-elektrika-color-bg: var(--color-2);
  --razlaga-racuna-elektrika-color-text: var(--color-1);
  --razlaga-racuna-toplota-color-bg: var(--color-2);
  --razlaga-racuna-toplota-color-bg2: var(--color-2);
  --razlaga-racuna-box-shadow-color: var(--color-1);

  --tp-arrows-background: var(--color-2);

  --left-arrow-carousel: url("/assets/images/ikone/wcag/left-arrow-black.svg");
  --right-arrow-carousel: url("/assets/images/ikone/wcag/right-arrow-black.svg");

  --mdc-switch-unselected-handle-color: var(--color-text);
  --mdc-switch-unselected-icon-color: var(--color-background);
  --mdc-switch-selected-handle-color: var(--color-text);
  --mdc-switch-selected-icon-color: var(--color-background);

  .mat-mdc-slide-toggle.mat-accent {
    --mdc-switch-selected-focus-state-layer-color: var(--color-text) !important;
    --mdc-switch-selected-handle-color: var(--color-text) !important;
    --mdc-switch-selected-hover-state-layer-color: var(--color-text) !important;
    --mdc-switch-selected-pressed-state-layer-color: var(--color-text) !important;

    --mdc-switch-selected-focus-handle-color: var(--color-text-dark) !important;
    --mdc-switch-selected-hover-handle-color: var(--color-text-dark) !important;
    --mdc-switch-selected-pressed-handle-color: var(--color-text-dark) !important;

    --mdc-switch-selected-focus-track-color: var(--color-text) !important;
    --mdc-switch-selected-hover-track-color: var(--color-text) !important;
    --mdc-switch-selected-pressed-track-color: var(--color-text) !important;
    --mdc-switch-selected-track-color: var(--color-text) !important;

    --mdc-switch-unselected-track-color: var(--color-text) !important;
    --mdc-switch-unselected-hover-handle-color: var(--color-text-dark) !important;
    --mdc-switch-unselected-hover-track-color: var(--color-text) !important;
    --mdc-switch-unselected-focus-handle-color: var(--color-text-dark) !important;
    --mdc-switch-unselected-focus-track-color: var(--color-text) !important;
  }
}

.theme-lime-black {

  --outline-color-main: lime;
  --outline-color-inverse: lime;

  --color-1: lime;
  --color-1-dark: #00e606;
  --color-2: #000;

  --color-white: var(--color-1);
  --color-yellow-dark: var(--color-2);
  --color-light-gray: var(--color-2);
  --color-black: var(--color-1);

  --color-white-inverted: var(--color-2);
  --color-white-reverse-on-dark: var(--color-1);
  --color-white-bg: var(--color-2);
  --color-white-t30: var(--color-1);
  --color-white-t15: var(--color-1);

  --color-black-border-t875: var(--color-1);

  --color-brown-dark: var(--color-1);
  --color-brown-dark-bg: var(--color-2);


  // Additional colors
  --color-text: var(--color-1);
  --color-text-dark: var(--color-1-dark);
  --color-bright: var(--color-1);
  --color-background: var(--color-2);
  --border-color: var(--color-1);
  --bottom-menu-color: var(--color-1);
  --error-color: var(--color-1);

  // Energetika colors
  --basic-color-blue-dark: var(--color-2);
  --basic-color-blue-light: var(--color-1);
  --basic-color-yellow: var(--color-2);
  --basic-color-green: var(--color-2);
  --basic-color-green-darker: var(--color-2);
  --basic-color-background-color: var(--color-2);
  --zvestoba-ogreva-color: var(--color-2);

  --klub-zvestoba-custom-button: #fff;

  --basic-color-blue-dark-text: var(--color-1);
  --basic-color-blue-dark-border: var(--color-1);

  --basic-color-green-t35: var(--color-2);
  --basic-color-blue-dark-t80: var(--color-2);
  --basic-color-blue-dark-t45: var(--color-2);
  --basic-color-blue-dark-t40: var(--color-2);
  --basic-color-blue-dark-t35: var(--color-2);
  --basic-color-blue-dark-t25: var(--color-2);
  --basic-color-blue-dark-t20: var(--color-2);
  --basic-color-blue-dark-l30: var(--color-2);
  --basic-color-blue-light-l30: var(--color-2);
  --basic-color-yellow-t20: var(--color-2);
  --zvestoba-ogreva-color-t15: var(--color-2);

  //components
  --button-border-color-light: var(--color-1);
  --button-border-color-gray: var(--color-1);
  --button-placeholder-color-text: var(--color-1);

  --color-border-gray-1: var(--color-1);
  --color-border-gray-2: var(--color-1);
  --color-border-gray-3: var(--color-1);
  --color-border-gray-4: var(--color-1);
  --color-border-gray-5: var(--color-1);

  --color-blue-bg-1: var(--color-2);
  --color-blue-bg-2: var(--color-1);

  --color-light-red-bg: var(--color-2);
  --color-light-red-bg-darkened-7: var(--color-1);

  --basic-color-blue-dark-button-bg: var(--color-1);
  --basic-color-blue-dark-t20-button-bg: var(--color-2);
  --basic-color-blue-dark-t40-button-bg: var(--color-2);
  --basic-color-blue-dark-t20-bg: var(--color-1);
  --basic-color-blue-dark-t40-bg: var(--color-1);

  --border-none-monochromatic-enabled: 2px solid var(--color-1);
  --border-color-transparent-monochromatic-enabled: var(--color-1);
  --background-color-transparent-monochromatic-enabled: var(--color-1);
  --background-color-transparent-monochromatic-enabled-inverse: var(--color-2);

  --background-stripes-nonchromatic: none;

  --navbar-border: var(--color-1);
  --navbar-background: var(--color-2);
  --navbar-color-shadow-black: var(--color-1);
  --navbar-color-shadow-white: var(--color-2);

  --navbar-dropdown-box-shadow: rgba(0, 255, 0, 0.46);

  --slider-arrows-layer-bg: var(--color-2);

  --header-bg: var(--color-2);

  --footer-bg: var(--color-2);
  --footer-color-gray: var(--color-1);

  --body-color-bg: var(--color-2);
  --body-color-text: var(--color-1);

  --text-color-gray-1: var(--color-1);
  --text-color-gray-2: var(--color-1);
  --text-color-gray-3: var(--color-1);
  --text-color-gray-4: var(--color-1);
  --text-color-gray-5: var(--color-1);
  --text-color-gray-6: var(--color-1);
  --text-color-gray-7: var(--color-1);

  --form-control-focus: var(--color-1);

  --input-background-color: var(--color-2);
  --input-text-color: var(--color-1);
  --input-placeholder-color: #598159; // lighten 50%

  --input-error-icon-url: url("/assets/images/ikone/exclamation-circle-lime.svg");
  --input-error-color: var(--color-1);

  --dropdown-menu-border: var(--color-1);

  --icons-color-gray-1: var(--color-1);
  --icons-fa-color-gray: var(--color-1);
  --icons-ul-li-fa-color: var(--color-1);

  --nipke-icons-url: url("/assets/images/energetika-v-druzbi/energetika-nipke-priporoca-icons-sprite-lime.png");
  --tick-icon-gray: url("/assets/images/general/wcag/li-tick-green.png");
  --tick-icon-green: url("/assets/images/general/wcag/li-tick-green.png");
  --tick-icon-input: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='lime' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");

  --sidebar-nav-color-text: var(--color-1);

  --table-color-gray-1-text: var(--color-1);
  --table-color-gray-1-border: var(--color-1);
  --table-color-gray-1-bg: var(--color-2);
  --table-color-caption-1: var(--color-1);

  --news-color-text: var(--color-1);
  --news-color-caption: var(--color-1);

  --copyright-link-bg: var(--color-2);

  --color-blue-light-bg: var(--color-2);
  --color-gray-1-bg: var(--color-2);

  --color-map-bg: var(--color-2);

  --tt-blue-dark-bg: var(--color-2);
  --tt-blue-dark-text: var(--color-1);

  --povprasevanje-lighter-text-color: var(--color-1);
  --povprasevanje-main-color-t70: var(--color-1);
  --povprasevanje-main-color-t85: var(--color-2);
  --povprasevanje-main-color-bg-t70: var(--color-2);

  --razlaga-racuna-plin-color-bg: var(--color-2);
  --razlaga-racuna-color-text: var(--color-1);
  --razlaga-racuna-elektrika-color-bg: var(--color-2);
  --razlaga-racuna-elektrika-color-text: var(--color-1);
  --razlaga-racuna-toplota-color-bg: var(--color-2);
  --razlaga-racuna-toplota-color-bg2: var(--color-2);
  --razlaga-racuna-box-shadow-color: var(--color-1);

  --tp-arrows-background: var(--color-2);

  --left-arrow-carousel: url("/assets/images/ikone/wcag/left-arrow-lime.svg");
  --right-arrow-carousel: url("/assets/images/ikone/wcag/right-arrow-lime.svg");

  --mdc-switch-unselected-handle-color: var(--color-text);
  --mdc-switch-unselected-icon-color: var(--color-background);
  --mdc-switch-selected-handle-color: var(--color-text);
  --mdc-switch-selected-icon-color: var(--color-background);

  .mat-mdc-slide-toggle.mat-accent {
    --mdc-switch-selected-focus-state-layer-color: var(--color-text) !important;
    --mdc-switch-selected-handle-color: var(--color-text) !important;
    --mdc-switch-selected-hover-state-layer-color: var(--color-text) !important;
    --mdc-switch-selected-pressed-state-layer-color: var(--color-text) !important;

    --mdc-switch-selected-focus-handle-color: var(--color-text-dark) !important;
    --mdc-switch-selected-hover-handle-color: var(--color-text-dark) !important;
    --mdc-switch-selected-pressed-handle-color: var(--color-text-dark) !important;

    --mdc-switch-selected-focus-track-color: var(--color-text) !important;
    --mdc-switch-selected-hover-track-color: var(--color-text) !important;
    --mdc-switch-selected-pressed-track-color: var(--color-text) !important;
    --mdc-switch-selected-track-color: var(--color-text) !important;

    --mdc-switch-unselected-track-color: var(--color-text) !important;
    --mdc-switch-unselected-hover-handle-color: var(--color-text-dark) !important;
    --mdc-switch-unselected-hover-track-color: var(--color-text) !important;
    --mdc-switch-unselected-focus-handle-color: var(--color-text-dark) !important;
    --mdc-switch-unselected-focus-track-color: var(--color-text) !important;
  }
}
