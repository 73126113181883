@import "variables";

@mixin media-query-lg {
    @media #{$media-query-lg-max} {
        @content;
    }
}

@mixin media-query-md {
    @media #{$media-query-md-max} {
        @content;
    }
}

@mixin media-query-sm {
    @media #{$media-query-sm-max} {
        @content;
    }
}

@mixin media-query-xs {
    @media #{$media-query-xs-max} {
        @content;
    }
}

@mixin media-query-xxs {
    @media #{$media-query-xxs-max} {
        @content;
    }
}

@mixin media-query-xs-min {
    @media #{$media-query-xs} {
        @content;
    }
}

@mixin media-query-sm-min {
    @media #{$media-query-sm} {
        @content;
    }
}

@mixin media-query-md-min {
    @media #{$media-query-md} {
        @content;
    }
}

@mixin media-query-lg-min {
    @media #{$media-query-lg} {
        @content;
    }
}

@mixin media-query-xl-min {
    @media #{$media-query-xl} {
        @content;
    }
}