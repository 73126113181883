@import "./theme/enlj-blue/variables";
@import "./theme/enlj-wcag/variables";

$screen-xs: 380px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxs-max: $screen-xs - 1;
$screen-xs-max: $screen-sm - 1;
$screen-sm-max: $screen-md - 1;
$screen-md-max: $screen-lg - 1;
$screen-lg-max: $screen-xl - 1;

$media-query-xs: "(min-width: #{$screen-xs})";
$media-query-sm: "(min-width: #{$screen-sm})";
$media-query-md: "(min-width: #{$screen-md})";
$media-query-lg: "(min-width: #{$screen-lg})";
$media-query-xl: "(min-width: #{$screen-xl})";
$media-query-xxs-max: "(max-width: #{$screen-xxs-max})";
$media-query-xs-max: "(max-width: #{$screen-xs-max})";
$media-query-sm-max: "(max-width: #{$screen-sm-max})";
$media-query-md-max: "(max-width: #{$screen-md-max})";
$media-query-lg-max: "(max-width: #{$screen-lg-max})";

$container-xs: 360px;
$container-sm: 540px;
$container-md: 720px;
$container-lg: 960px;
$container-xl: 1204px;

$font-family: var(--font-family-default);

// bootstrap overrides
$grid-breakpoints: (
  xxs: 0,
  xs: $screen-xs,
  sm: $screen-sm,
  md: $screen-md,
  lg: $screen-lg,
  xl: $screen-xl
);

$container-max-widths: (
  xs: $container-xs,
  sm: $container-sm,
  md: $container-md,
  lg: $container-lg,
  xl: $container-xl
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3),
  6: ($spacer * 4.5),
  7: ($spacer * 6),
);
