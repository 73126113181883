@import "variables";
@import "mixins";

.text-color-zvestoba {
	color: var(--zvestoba-ogreva-color) !important;
}

.transform-none {
  text-transform: var(--no-text-transform);
}

.transform-capitalize {
  display: block;
}

.transform-capitalize:first-letter {
  text-transform: uppercase;
}
